<template>
  <div id="fullScreenDiv">
    <v-overlay v-if="screenLock" class="text-center">
      <v-text-field type="password" v-model="password"></v-text-field>
      <v-btn @click="disableLock" outlined>잠금 해제</v-btn>
    </v-overlay>
    <div>
      <v-stepper v-if="!ready" v-model="e6" vertical>
        <v-stepper-step :complete="e6 > 1" step="1">
          음악 선택
          <small v-if="selectedMusic.length > 0 && e6 > 1" style="color: darkblue">
            <br />
            {{ selectedMusic[0].filename }}
          </small>
          <v-btn class="mx-2" @click="goBack" small v-if="e6 === 1" outlined>
            뒤로 가기
          </v-btn>
        </v-stepper-step>

        <v-stepper-content step="1">
          <!--          <v-btn-->
          <!--            color="primary"-->
          <!--            class="mx-2"-->
          <!--            :disabled="selectedMusic.length === 0"-->
          <!--            @click="e6 = 2"-->
          <!--          >-->
          <!--            선택 완료-->
          <!--          </v-btn>-->

          <v-btn class="mx-2" small v-if="e6 === 1" outlined
                 :color="useDefaultMusic? 'primary' : 'black'"
          @click="setUseDefaultMusic(true)">
            기본 제공에서 음악 선택
          </v-btn>
          <v-btn class="mx-2" small v-if="e6 === 1" outlined
                 :color="useDefaultMusic? 'black' : 'primary'"
                 @click="setUseDefaultMusic(false)">
            하드 디스크에서 음악 선택
          </v-btn>
          <br>
          <br>
          <v-row v-if="useDefaultMusic">
            <v-col cols="12">
              <h3>기본 제공 음악에서 선택</h3>
              <v-data-table
                disable-sort
                :headers="musicHeader"
                :items="musicItem"
                :single-select="true"
                item-key="src"
                :items-per-page="20"
                @click:row="handleOne"
                hide-default-footer
              >
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="!useDefaultMusic">
            <v-col cols="12">
              <h3>하드 디스크에서 음악 고르기</h3>
              {{localFile}}
              <v-file-input
                accept="audio/3gpp, video/3gpp2, audio/webm, audio/ogg, audio/aac, audio/x-wav"
                label="업로드"
                truncate-length="50"
                v-model="localFile"
                @change="handleOneLocal"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-step :complete="e6 > 2" step="2">
          주파수 필터링 설정
          <small v-if="selectedFilter.length > 0 && e6 > 2" style="color: darkblue">
            <br />
            {{ selectedFilter[0].type }}
          </small>
          <v-btn class="mx-2" @click="e6 = 1" small v-if="e6 === 2" outlined> 뒤로 가기 </v-btn>
        </v-stepper-step>
        <v-stepper-content step="2">
          <!--          <v-btn-->
          <!--            class="mx-2"-->
          <!--            color="primary"-->
          <!--            @click="e6 = 3"-->
          <!--            :disabled="selectedFilter.length === 0"-->
          <!--          >-->
          <!--            선택 완료-->
          <!--          </v-btn>-->
          <v-row>
            <v-col cols="6">
              <v-data-table
                disable-sort
                :headers="filterHeader"
                :items="filterItem_1"
                :single-select="true"
                item-key="type"
                :items-per-page="20"
                disable-pagination
                disable-filtering
                hide-default-footer
                @click:row="handleTwo"
              >
              </v-data-table>
            </v-col>
            <v-col cols="6">
              <v-data-table
                disable-sort
                :headers="filterHeader"
                :items="filterItem_2"
                :single-select="true"
                item-key="type"
                :items-per-page="20"
                @click:row="handleTwo"
                disable-pagination
                disable-filtering
                hide-default-footer
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3">
          재생 장치 설정
          <small v-if="selectedAir !== '' && e6 > 3" style="color: darkblue">
            <br />
            공기전도 출력장치 : {{ selectedAir.label }}
          </small>
          <small v-if="selectedBone !== '' && e6 > 3" style="color: darkblue">
            골전도 출력장치 : {{ selectedBone.label }}
          </small>
          <small v-if="selectedMic !== '' && e6 > 3" style="color: darkblue">
            마이크 : {{ selectedMic.label }}
          </small>
          <v-btn class="mx-2" @click="e6 = 2" small v-if="e6 === 3" outlined> 뒤로 가기 </v-btn>
          <v-btn
            class="mx-2"
            color="primary"
            @click="e6 = 4"
            :disabled="selectedAir === '' || selectedBone === ''"
            outlined
            small
            v-if="e6 === 3"
          >
            선택 완료
          </v-btn>
        </v-stepper-step>

        <v-stepper-content step="3">
          <br /><br />

          <v-col class="d-flex" cols="6" sm="6">
            <v-select
              return-object
              :items="audioDevice"
              v-model="selectedAir"
              item-text="label"
              label="공기 전도 출력"
              dense
              @change="handleThree"
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="6" sm="6">
            <v-select
              :return-object="true"
              :items="audioDevice"
              v-model="selectedBone"
              item-text="label"
              key="deviceId"
              label="골전도 출력"
              dense
              @change="handleThree"
            ></v-select>
          </v-col>
          <!--          <v-col-->
          <!--            class="d-flex"-->
          <!--            cols="12"-->
          <!--            sm="6"-->
          <!--          >-->
          <!--            <v-select-->
          <!--              :return-object=true-->
          <!--              :items="micDevices"-->
          <!--              v-model="selectedMic"-->
          <!--              item-text="label"-->
          <!--              key="deviceId"-->
          <!--              label="마이크"-->
          <!--              dense-->
          <!--            ></v-select>-->
          <!--          </v-col>-->
        </v-stepper-content>

        <v-stepper-step step="4" :complete="e6 > 4">
          공기전도 좌측 볼륨 설정
          <small v-if="e6 > 4" style="color: darkblue">
            <br />
            {{ selectedLeftVolume[0].description }}
          </small>
          <v-btn class="mx-2" @click="e6 = 3" small v-if="e6 === 4" outlined> 뒤로 가기 </v-btn>
        </v-stepper-step>
        <v-stepper-content step="4">
          <!--          <v-btn-->
          <!--            class="mx-2"-->
          <!--            color="primary"-->
          <!--            @click="e6 = 5"-->
          <!--          >-->
          <!--            선택 완료-->
          <!--          </v-btn>-->
          <v-row>
            <v-col cols="6">
              <v-data-table
                disable-sort
                :headers="leftVolumeHeader"
                :items="leftVolumeItem_1"
                :single-select="true"
                item-key="pan"
                :items-per-page="20"
                @click:row="handleFour"
                hide-default-footer
              >
              </v-data-table>
            </v-col>
            <v-col cols="6">
              <v-data-table
                disable-sort
                :headers="leftVolumeHeader"
                :items="leftVolumeItem_2"
                :single-select="true"
                item-key="pan"
                :items-per-page="20"
                @click:row="handleFour"
                hide-default-footer
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-step step="5" :complete="e6 > 5">
          공기전도 지연시간 설정
          <small v-if="e6 > 5" style="color: darkblue">
            <br />
            공기전도 지연시간 : {{ selectedBoneLatency[0].latency }}ms
          </small>
          <v-btn class="mx-2" @click="e6 = 4" small v-if="e6 === 5" outlined> 뒤로 가기 </v-btn>
        </v-stepper-step>
        <v-stepper-content step="5">
          <!--          <v-btn-->
          <!--            class="mx-2"-->
          <!--            color="primary"-->
          <!--            @click="e6 = 6, ready = true"-->
          <!--          >-->
          <!--            선택 완료-->
          <!--          </v-btn>-->
          <v-row>
            <v-col cols="4">
              <v-data-table
                disable-sort
                :headers="boneLatencyHeader"
                :items="boneLatencyItem_1"
                :single-select="true"
                item-key="latency"
                :items-per-page="20"
                @click:row="handleFive"
                hide-default-footer
              >
              </v-data-table>
            </v-col>
            <v-col cols="4">
              <v-data-table
                disable-sort
                :headers="boneLatencyHeader"
                :items="boneLatencyItem_2"
                :single-select="true"
                item-key="latency"
                :items-per-page="20"
                @click:row="handleFive"
                hide-default-footer
              >
              </v-data-table>
            </v-col>
            <v-col cols="4">
              <v-data-table
                disable-sort
                :headers="boneLatencyHeader"
                :items="boneLatencyItem_3"
                :single-select="true"
                item-key="latency"
                :items-per-page="20"
                @click:row="handleFive"
                hide-default-footer
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </div>
    <div v-if="ready">
      <div style="position: absolute; top: 2%; right: 2%; width: 35%">
        <v-sheet style="min-height: 85vh">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>다시 설정 </v-list-item-title>
              <v-list-item-subtitle>
                <v-btn class="mt-3" color="green" outlined small @click="reload">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>화면 잠금 </v-list-item-title>
              <v-list-item-subtitle>
                <v-btn class="mt-3" color="red" outlined small @click="lock">
                  <v-icon>mdi-lock</v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>음악</v-list-item-title>
              <v-list-item-subtitle>{{ selectedMusic[0].filename }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>필터</v-list-item-title>
              <v-list-item-subtitle>
                {{ selectedFilter[0].type }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>좌우 볼륨</v-list-item-title>
              <v-list-item-subtitle>{{ selectedLeftVolume[0].type }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>공기전도 지연시간</v-list-item-title>
              <v-list-item-subtitle>
                {{ selectedBoneLatency[0].type }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <br />

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>재생 컨트롤</v-list-item-title>
              <v-list-item-subtitle>
                <br />
                <div :style="isSeeking ? 'pointer-events:none' : ''">
                  <audio
                    v-show="true"
                    id="sourceAir"
                    controls
                    @seeked="onSeekd"
                    @play="onSeekd"
                    @pause="onPaused"
                    style="display: block; margin-left: auto; margin-right: auto; width: 100%"
                  ></audio>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>골전도 볼륨 {{ boneGainLevel }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-slider
                  class="pl-3"
                  v-model="boneGainLevel"
                  :min="0"
                  :max="100"
                  :step="1"
                  prepend-icon="mdi-volume-high"
                ></v-slider>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>공기전도 볼륨 {{ airGainLevel }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-slider
                  class="mt-3 pl-3"
                  v-model="airGainLevel"
                  :min="0"
                  :max="100"
                  :step="1"
                  prepend-icon="mdi-volume-high"
                ></v-slider>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>훈련 시간 </v-list-item-title>
              <v-list-item-subtitle>
                {{ trainingTimeInSeconds }} 초
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>훈련 종료 </v-list-item-title>
              <v-list-item-subtitle>
                <v-btn class="mt-3" color="primary" outlined small @click="endTraining">
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
      </div>
      <div class="equalizer" v-if="ready">
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
        <div class="equalizer-bar">
          <span></span>
        </div>
      </div>
      <v-row v-show="false" xs12 sm6 md4>
        <v-card max-width="50vw" height="50vh" class="ma-5 d-flex flex-column">
          <v-card-title>Player</v-card-title>
          <v-card-subtitle class="pb-6"> 장비별 재생 여부 및 볼륨 컨트롤 </v-card-subtitle>
          <v-card-text class="text--primary">
            공기전도<br /><br />
            <audio id="playerAir" controls></audio><br /><br />
            공기전도 게인 : {{ airGainLevel }} db
            <br />
            골전도<br /><br />
            <audio id="playerBone" controls></audio><br /><br />
            골전도 게인 : {{ boneGainLevel }} db
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-row>
      <audio v-show="true" id="sourceBone"></audio>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
// import * as Tone from "tone";

let Tone = null;

const WIDTH = 640;
const HEIGHT = 360;

// Interesting parameters to tweak!
const SMOOTHING = 0.8;
const FFT_SIZE = 2048;

const MAX_BAR_HEIGHT = 18;

window.requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

function BufferLoader(context, urlList, callback) {
  this.context = context;
  this.urlList = urlList;
  this.onload = callback;
  this.bufferList = new Array();
  this.loadCount = 0;
}

BufferLoader.prototype.loadBuffer = function (url, index) {
  // Load buffer asynchronously
  const request = new XMLHttpRequest();
  request.open("GET", url, true);
  request.responseType = "arraybuffer";

  const loader = this;

  request.onload = function () {
    // Asynchronously decode the audio file data in request.response
    loader.context.decodeAudioData(
      request.response,
      (buffer) => {
        if (!buffer) {
          alert(`error decoding file data: ${url}`);
          return;
        }
        console.log("buffer done!");
        loader.bufferList[index] = buffer;
        if (++loader.loadCount == loader.urlList.length) loader.onload(loader.bufferList);
      },
      (error) => {
        console.error("decodeAudioData error", error);
      }
    );
  };

  request.onerror = function () {
    alert("BufferLoader: XHR error");
  };

  request.send();
};

BufferLoader.prototype.load = function () {
  for (let i = 0; i < this.urlList.length; ++i) this.loadBuffer(this.urlList[i], i);
};

function loadSounds(obj, soundMap, callback, context) {
  // Array-ify
  const names = [];
  const paths = [];
  for (const name in soundMap) {
    const path = soundMap[name];
    names.push(name);
    paths.push(path);
  }
  let bufferLoader = new BufferLoader(context, paths, (bufferList) => {
    for (let i = 0; i < bufferList.length; i++) {
      const buffer = bufferList[i];
      const name = names[i];
      obj[name] = buffer;
    }
    if (callback) {
      callback();
    }
  });
  bufferLoader.load();
}

function VisualizerSample(context, src) {
  this.context = context;
  this.analyser = context.createAnalyser();
  this.analyser.connect(context.destination);
  this.analyser.minDecibels = -140;
  this.analyser.maxDecibels = 0;
  loadSounds(
    this,
    {
      buffer: src,
    },
    onLoaded,
    context
  );

  function onLoaded() {}
  this.freqs = new Uint8Array(this.analyser.frequencyBinCount);
  this.times = new Uint8Array(this.analyser.frequencyBinCount);

  this.isPlaying = false;
  this.startTime = 0;
  this.startOffset = 0;
}

// Toggle playback
VisualizerSample.prototype.togglePlayback = function () {
  if (this.isPlaying) {
    // Stop playback
    this.source[this.source.stop ? "stop" : "noteOff"](0);
    this.startOffset += this.context.currentTime - this.startTime;
    console.log("paused at", this.startOffset);
    // Save the position of the play head.
  } else {
    this.startTime = this.context.currentTime;
    console.log("started at", this.startOffset);
    this.source = this.context.createBufferSource();
    // Connect graph
    this.source.connect(this.analyser);
    this.source.buffer = this.buffer;
    this.source.loop = true;
    // Start playback, but make sure we stay in bound of the buffer.
    this.source[this.source.start ? "start" : "noteOn"](0, this.startOffset % 30);
    // Start visualizer.
    window.requestAnimFrame(this.draw.bind(this));
  }
  this.isPlaying = !this.isPlaying;
};

VisualizerSample.prototype.draw = function () {
  this.analyser.smoothingTimeConstant = SMOOTHING;
  this.analyser.fftSize = FFT_SIZE;

  // Get the frequency data from the currently playing music
  this.analyser.getByteFrequencyData(this.freqs);
  this.analyser.getByteTimeDomainData(this.times);

  // console.log(this.freqs);
  // console.log(this.times);
  // const width = Math.floor(1 / this.freqs.length, 10);

  const canvas = document.querySelector("canvas");
  const drawContext = canvas.getContext("2d");
  canvas.width = WIDTH;
  canvas.height = HEIGHT;
  // Draw the frequency domain chart.
  for (let i = 0; i < this.analyser.frequencyBinCount; i += 1) {
    const value = this.freqs[i];
    const percent = value / 256;
    const height = HEIGHT * percent;
    const offset = HEIGHT - height - 1;
    const barWidth = WIDTH / this.analyser.frequencyBinCount;
    // eslint-disable-next-line no-mixed-operators
    const hue = (i / this.analyser.frequencyBinCount) * 360;
    drawContext.fillStyle = `hsl(${hue}, 100%, 50%)`;
    drawContext.fillRect(i * barWidth, offset, barWidth, height);
  }

  // Draw the time domain chart.
  for (let i = 0; i < this.analyser.frequencyBinCount; i += 1) {
    const value = this.times[i];
    const percent = value / 256;
    const height = HEIGHT * percent;
    const offset = HEIGHT - height - 1;
    const barWidth = WIDTH / this.analyser.frequencyBinCount;
    drawContext.fillStyle = "white";
    drawContext.fillRect(i * barWidth, offset, 1, 2);
  }

  if (this.isPlaying) {
    window.requestAnimFrame(this.draw.bind(this));
  }
};

VisualizerSample.prototype.getFrequencyValue = function (freq) {
  const nyquist = this.context.sampleRate / 2;
  // eslint-disable-next-line no-mixed-operators
  const index = Math.round((freq / nyquist) * this.freqs.length);
  return this.freqs[index];
};

export default {
  name: "Player",
  props: {
    centerId: String,
    userName: String,
    userCode: String,
    userId: String,
    remainTime: Number,
  },
  async beforeDestroy() {
    this.isTraining = false;
    clearInterval(this.setRandomBarInterval);
    clearInterval(this.serverCheckInterval);
    clearInterval(this.sync);
    clearInterval(this.diffInterval);
    if(this.trainingId !== ''){
      await this.endTraining();
    }
  },
  async mounted() {
    this.isTraining = false;

    if(this.remainTime < 1){
      this.noRemainTime();
    }
    clearInterval(this.setRandomBarInterval);

    this.setRandomBarInterval = setInterval(() => {
      this.setRandomBars();
      if (this.isTraining) {
        this.trainingTime += 33;
        this.trainingTimeInSeconds = Math.floor(this.trainingTime / 1000);
      }
    }, 33);

    clearInterval(this.serverCheckInterval);

    this.serverCheckInterval = setInterval(async () => {
      if(await this.checkTrainingIsValid()){
        await this.updateTrainingTime()
      }else{
        if(this.isTraining){
          await this.forceEndTraining();
        }
      }
    }, 1000 * 5);
    const result = await axios.get("/music/info.json");
    this.musicItem = result.data.info;

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(async (s) => {
        s.getTracks().forEach((track) => {
          track.stop();
        });
        const devices = await navigator.mediaDevices.enumerateDevices();
        console.log(devices);

        const audioDevices = devices.filter(
          (device) => device.kind === "audiooutput" && device.label.indexOf("Default") === -1
        );
        const micDevices = devices.filter(
          (device) => device.kind === "audioinput" && device.label.indexOf("Default") === -1
        );
        this.audioDevice = JSON.parse(JSON.stringify(audioDevices));
        this.micDevices = JSON.parse(JSON.stringify(micDevices));
        console.log(this.audioDevice);
        console.log(this.micDevices);
      })
      .catch((error) => {
        console.log("Error :", error);
      });

    // Tone.context.createMediaStreamDestination();
    // const sample = new VisualizerSample(Tone.context, this.musicItem[0].src);

    // this.prepareAudio();
    // console.log(Tone.context.destination);
  },
  watch: {
    boneGainLevel(val) {
      let min = -72;
      let max = 12;

      let targetGain = 0;

      if (val == 50) {
        targetGain = 0;
      } else if (val < 50) {
        targetGain = min * ((50 - val) / 50);
      } else if (val > 50) {
        targetGain = max * ((val - 50) / 50);
      }
      this.boneGain.volume.value = targetGain;
    },
    airGainLevel(val) {
      let min = -72;
      let max = 12;

      let targetGain = 0;

      if (val == 50) {
        targetGain = 0;
      } else if (val < 50) {
        targetGain = min * ((50 - val) / 50);
      } else if (val > 50) {
        targetGain = max * ((val - 50) / 50);
      }
      this.airGain.volume.value = targetGain;
    },
    boneMicGainLevel(val) {
      this.boneMicGain.volume.value = val;
    },
    airMicGainLevel(val) {
      this.airMicGain.volume.value = val;
    },
    ready(val) {
      if (val) {
        setTimeout(() => {
          this.prepareAudio();
        }, 500);
      }
    },
    optionreverb: {
      // This will let Vue know to look inside the array
      deep: true,
      handler(object) {
        let vue = this;
        clearInterval(this.reverbInterval);
        if (object.active) {
          if (this.reverb.bypass) {
            this.reverb.bypass = false;
          }
          this.reverb.effect.wet = object.parameter.wet;
          this.reverb.effect.decay = object.parameter.decay + 0.001;
          // if (object.random) {
          //   this.reverbInterval = setInterval(() => {
          //     vue.optionreverb.parameter.decay = Math.floor(Math.random() * 40);
          //     vue.optionreverb.parameter.wet = Math.random()
          //   }, 1000 / vue.optionreverb.interval)
          // }
        } else {
          if (!this.reverb.bypass) {
            this.reverb.bypass = true;
          }
        }
      },
    },
    option3dpanner: {
      // This will let Vue know to look inside the array
      deep: true,
      handler(object) {
        let vue = this;
        clearInterval(this.panner3dInterval);
        if (object.active) {
          if (this._3dpanner.bypass) {
            this._3dpanner.bypass = false;
          }
          this._3dpanner.effect.setPosition(
            object.parameter.positionX,
            object.parameter.positionY,
            object.parameter.positionZ
          );
          // console.log(object.parameter.positionX);
          // if (object.random) {
          //   this.panner3dInterval = setInterval(() => {
          //     vue.option3dpanner.parameter.positionX = -2 + Math.random() * 4
          //     vue.option3dpanner.parameter.positionY = -2 + Math.random() * 4
          //     vue.option3dpanner.parameter.positionZ = -2 + Math.random() * 4
          //   }, 1000 / vue.option3dpanner.interval)
          // }
        } else {
          if (!this._3dpanner.bypass) {
            this._3dpanner.bypass = true;
          }
        }
      },
    },
  },
  methods: {
    setUseDefaultMusic(value){
      this.useDefaultMusic = value;
    },
    async goBack(){
      if(this.trainingId){
        await this.endTraining();
      }
      this.$emit('goBack');
    },
    async createTraining(){
      try{
        console.log('createTraining!');
        // console.log(this.selectedBoneLatency[0].type); //latency
        // console.log(this.selectedLeftVolume[0].type); //volume
        // console.log(this.selectedFilter[0].type); //filter
        // console.log(this.selectedMusic[0].filename); //music
        const response = await axios.post('https://listening-able.visualcamp.net/api/training', {
          userName: this.userName,
          userCode: this.userCode,
          userId: this.userId,
          centerId: this.centerId,
          latency: this.selectedBoneLatency[0].type,
          volume: this.selectedLeftVolume[0].type,
          filter: this.selectedFilter[0].type,
          music: this.selectedMusic[0].filename,
          startTime: Date.now(),
        }, {withCredentials: true});

        console.log('createTraining', response.data);
        if(response.status === 200 && response.data.training){
          this.trainingId = response.data.training._id;
          return true;
        }else{
          throw 'error';
        }
      }catch(e){
        console.log(e);
        return false;
      }
    },
    async updateTrainingSetting(){
      try{
        const response = await axios.post('https://listening-able.visualcamp.net/api/training/' + this.trainingId, {
          userName: this.userName,
          userCode: this.userCode,
          centerId: this.centerId,
          latency: this.selectedBoneLatency[0].type,
          volume: this.selectedLeftVolume[0].type,
          filter: this.selectedFilter[0].type,
          music: this.selectedMusic[0].filename,
        }, {withCredentials: true})

        console.log('updateTrainingSetting', response.data);
        if(response.status === 200 && response.data.training){
          return true;
        }else{
          throw 'error';
        }
      }catch(e){
        console.log(e);
        return false;
      }
    },
    async checkTrainingIsValid(){
      try{
        const response = await axios.get('https://listening-able.visualcamp.net/api/training/' + this.trainingId, {withCredentials: true})
        console.log('checkTrainingIsValid', response.data);
        if(response.status === 200 && response.data.training){
          if(response.data.training.chargeId){
            return false;
          }
          return true;
        }else{
          throw 'error';
        }
      }catch(e){
        console.log(e);
        return false;
      }
    },
    async updateTrainingTime(){
      try{
        const response = await axios.post('https://listening-able.visualcamp.net/api/training/' + this.trainingId, {
          trainingDuration: this.trainingTime
        }, {withCredentials: true})

        console.log('updateTrainingTime', response.data);
        if(response.status === 200 && response.data.training){
          return true;
        }else{
          throw 'error';
        }
      }catch(e){
        console.log(e);
        return false;
      }
    },
    async noRemainTime(){
      alert('잔여 시간이 모두 소진되었습니다. 관리자에게 문의하세요.')
      this.$emit("endTraining");
    },
    async forceEndTraining() {
      if(this.isEnded) return;
      console.log('forceEndTraining')
      await this.endTraining();
      // alert('현재 진행중이 트레닝이 유효하지 않습니다.')
      this.$emit("endTraining");
    },
    async endTraining() {
        if(this.isEnded) return;
      this.isEnded = true;
       try{
         const response = await axios.post('https://listening-able.visualcamp.net/api/training/' + this.trainingId + '/end', {
           trainingDuration: this.trainingTime,
           endTime: Date.now(),
         }, {withCredentials: true})

        console.log('endTraining', response.data);
        if(response.status === 200 && response.data.training){
          this.$emit("endTraining");
          setTimeout(()=> {
            alert('훈련이 정상적으로 종료되었습니다.')
          },500)
        }else{
          throw 'error';
        }
      }catch(e){
         console.log(e);
         this.$emit("endTraining");
         setTimeout(()=> {
           ('훈련이 비 정상적으로 종료되었습니다. 관리자에게 문의하세요.')
         },500)
       }
    },
    async disableLock() {
      try{
        const response = await axios.post('https://listening-able.visualcamp.net/api/auth/adminlock',
          {
            password: this.password,
            centerId: this.centerId
          },{withCredentials:true});

        console.log(response);
        if(response.status === 200 && response.data.result === true){
          this.screenLock = false;
        }
        this.password = "";

      }catch(e){
        this.password = "";

      }
    },
    reload() {
      this.ready = false;
      this.e6 = 1;
      document.exitFullscreen();
      // window.location.reload()
    },
    handleOne(value) {
      import("tone").then((module) => {
        Tone = module;
        this.selectedMusic[0] = value;
        this.e6 = 2;
      });
    },
    handleOneLocal(file){
      import("tone").then((module) => {
        Tone = module;
        this.selectedMusic[0] = {
          filename:file.name,
          playtime: 0,
          fileSize: file.size,
          mime: file.type,
          src:URL.createObjectURL(file),
        };
        this.e6 = 2;
      });
      console.log(this.localFile)
      console.log(file)
    },
    handleTwo(value) {
      this.selectedFilter[0] = value;
      this.e6 = 3;
    },
    handleThree(value) {
      if (this.selectedBone && this.selectedAir) {
        this.e6 = 4;
      }
    },
    handleFour(value) {
      this.selectedLeftVolume[0] = value;
      this.e6 = 5;
    },
    async handleFive(value) {
      this.selectedBoneLatency[0] = value;
      this.e6 = 6;
      if(this.trainingId === ''){
        if(await this.createTraining()){
          this.ready = true;
          document.getElementById("fullScreenDiv").requestFullscreen();
          this.password = "";
        }else{
          this.forceEndTraining();
        }
      }else{
        if(await this.updateTrainingSetting()){
          this.ready = true;
          document.getElementById("fullScreenDiv").requestFullscreen();
          this.password = "";
        }else{
          this.forceEndTraining();
        }
      }
    },
    onPaused(event) {
      const sourceAudioElementBone = document.getElementById("sourceBone");
      if (sourceAudioElementBone) {
        sourceAudioElementBone.pause();
      }
      this.isTraining = false;
    },
    lock() {
      this.screenLock = true;
    },
    onSeekd(event) {
      // event.timeStamp;

      if (this.isSeeking) {
        return;
      }

      if(this.syncSuccess){
        this.isTraining = true;
      }

      this.isSeeking = true;
      const sourceAudioElementBone = document.getElementById("sourceBone");
      const sourceAudioElementAir = document.getElementById("sourceAir");

      const destinationAudioElementBone = document.getElementById("playerBone");
      const destinationAudioElementAir = document.getElementById("playerAir");

      // sourceAudioElementBone.currentTime = event.srcElement.currentTime;

      clearInterval(this.sync);
      this.syncSuccess = false;
      this.isSyncing = false;

      this.sync = setInterval(async () => {
        const that = this;
        if(that.syncSuccess || that.isSyncing){
            console.log('returnreturn', that.syncSuccess, that.isSyncing);
          return;
        }
        that.isSyncing = true;
        const delay = that.selectedBoneLatency[0].latency / 1000;
        const margin = 0.005;
        const el1 = sourceAudioElementBone;
        const el2 = sourceAudioElementAir;
        const diff = el1.currentTime - el2.currentTime;
        // console.log('delay?', delay);
        // console.log('margin?', margin);
        // console.log(delay, diff);
        if (Math.abs(diff) <= margin && delay < 0.005) {
          console.log('loop 1', diff, el1.currentTime, el2.currentTime);
          if(el1.paused){
            el1.play();
          }
          if(el2.paused){
            el2.play();
          }
          that.enduranceCount = 0;
          that.successCount += 1;
          if (that.successCount > 2) {
            that.syncSuccess = true;

            console.log("clear!!!!!1", diff);

            destinationAudioElementAir.pause();
            destinationAudioElementBone.pause();

            setTimeout(() => {
              destinationAudioElementAir.play();
              destinationAudioElementBone.play();
            }, 100);
            clearInterval(that.sync);
            setTimeout(() => {
              that.isSeeking = false;
              that.isTraining = true;
            }, 500);

            clearInterval(that.diffInterval);
            that.diffInterval = setInterval(() => {
              const diff2 = el1.currentTime - el2.currentTime;
              console.log('time diff1', diff2, el1.currentTime, el2.currentTime);
              if(diff2 > delay + margin || diff2 < -0.01){
                console.log('force onseekd1', delay);
                that.onSeekd();
              }
            }, 1000);
          }
        } else if (diff > delay + margin) {
          console.log('loop 2', diff, el1.currentTime, el2.currentTime);

          // console.log('2')
          that.successCount = 0;
          that.enduranceCount += 1;
          if (that.enduranceCount > 5) {
            console.log("p1 pause", diff);
            if(el2.currentTime + delay > el2.duration){
              this.syncSuccess = true;
              el1.pause();
              el2.pause();
              el1.currentTime = 0;
              el2.currentTime = 0;
              destinationAudioElementAir.pause();
              destinationAudioElementBone.pause();

              clearInterval(that.diffInterval);
              clearInterval(that.sync);

              setTimeout(() => {
                that.isSeeking = false;
              }, 500);

              // clearInterval(that.diffInterval);
              // that.diffInterval = setInterval(() => {
              //   const diff2 = el1.currentTime - el2.currentTime;
              //   console.log('time diff1', diff2, el1.currentTime, el2.currentTime);
              //   if(diff2 > delay || diff2 < -0.01){
              //     console.log('force onseekd1', delay);
              //     that.onSeekd();
              //   }
              // }, 1000);

              return;
            }
            el1.currentTime = Math.min(el2.currentTime + delay, el2.duration - margin);
            if(!el1.paused){
              el1.pause();
            }
            if(el2.paused){
              el2.play();
            }
            that.enduranceCount = 0;
          }
        } else if (diff < delay - margin) {
          console.log('loop 3', diff, el1.currentTime, el2.currentTime);
          that.enduranceCount += 1;
          that.successCount = 0;
          if (that.enduranceCount > 5) {
            console.log("p2 pause", diff);
            if(el2.currentTime + delay > el2.duration){
              console.log('p2 return loop');
              this.syncSuccess = true;
              el1.pause();
              el2.pause();
              el1.currentTime = 0;
              el2.currentTime = 0;
              destinationAudioElementAir.pause();
              destinationAudioElementBone.pause();

              clearInterval(that.diffInterval);
              clearInterval(that.sync);

              setTimeout(() => {
                that.isSeeking = false;
              }, 500);

              clearInterval(that.diffInterval);
              that.diffInterval = setInterval(() => {
                const diff2 = el1.currentTime - el2.currentTime;
                console.log('time diff1', diff2, el1.currentTime, el2.currentTime);
                if(diff2 > delay + 0.1 ||  diff2 < delay - 0.1 || diff2 < -0.01){
                  console.log('force onseekd1', delay, diff2);
                  that.onSeekd();
                }
              }, 1000);

              return;
            }

            el1.currentTime = Math.min(el2.currentTime + delay, el2.duration - margin);
            if(el1.paused){
              el1.play();
            }
            if(!el2.paused){
              el2.pause();
            }
            that.enduranceCount = 0;
          }
        } else {
          console.log('loop 4', diff, el1.currentTime, el2.currentTime);
          if(el1.paused){
            el1.play();
          }
          if(el2.paused){
            el2.play();
          }
          that.enduranceCount = 0;
          that.successCount += 1;
          if (that.successCount > 2) {
            this.syncSuccess = true;
            console.log("2clear!!!!!", diff);

            destinationAudioElementAir.pause();
            destinationAudioElementBone.pause();

            setTimeout(() => {
              destinationAudioElementAir.play();
              destinationAudioElementBone.play();
            }, 100);
            clearInterval(that.sync);
            setTimeout(() => {
              that.isSeeking = false;
              that.isTraining = true;
            }, 500);

            clearInterval(that.diffInterval);
            that.diffInterval = setInterval(() => {
              const diff2 = el1.currentTime - el2.currentTime;
              console.log('time diff2', diff2, el1.currentTime, el2.currentTime);
              if(diff2 > delay + 0.1 ||  diff2 < delay - 0.1 || diff2 < -0.01){
                console.log('force onseekd2', delay, diff2);
                that.onSeekd();
              }
            }, 1000);
          }
        }
        that.isSyncing = false;
      }, 10);
    },
    async prepareAudio() {
      class FX extends Tone.ToneAudioNode {
        constructor(options) {
          super();
          this.effect = new options.effect(options.options); // create effectNode in constructor
          this._bypass = options.bypass;
          this._lastBypass = options.bypass;

          // audio signal is constantly passed through this node,
          // but processed by effect only, if bypass prop is set to `false`
          this.input = new Tone.Gain();
          this.output = new Tone.Gain();

          this.effect.connect(this.output);

          this.activate(!options.bypass); // initialize input node connection
        }

        get bypass() {
          return this._bypass;
        }

        set bypass(val) {
          if (this._lastBypass === val) return;

          this._bypass = Boolean(val);
          this.activate(!val);
          this._lastBypass = val;
        }

        /*
       activate effect (connect input node), if bypass == false
       */
        activate(doActivate) {
          if (doActivate) {
            this.input.disconnect();
            this.input.connect(this.effect);
          } else {
            this.input.disconnect();
            this.input.connect(this.output);
          }
        }

        toggleBypass() {
          this.bypass = !this._bypass;
        }

        dispose() {
          super.dispose();
          this.effect.dispose();
          return this;
        }
      }

      let that = this;
      this.addBarSpans();
      this.initDecibelList(32);
      // if (navigator.getUserMedia) {
      //   navigator.getUserMedia(
      //     {
      //       audio: {
      //         autoGainControl: false,
      //         echoCancellation: false,
      //         noiseSuppression: false,
      //         mozAutoGainControl: false,
      //         mozNoiseSuppression: false,
      //         deviceId: { exact: this.selectedMic.deviceId },
      //       }
      //     },
      //     function (stream) {
      //       // aCtx = new AudioContext();
      //
      //       try {
      //         that.airMicGain = new Tone.Volume(0);
      //         that.boneMicGain = new Tone.Volume(0);
      //
      //         const _stream = Tone.context.createMediaStreamSource(stream);
      //         const _stream2 = Tone.context.createMediaStreamSource(stream);
      //
      //         Tone.connect(_stream, that.airMicGain)
      //         Tone.connect(_stream2, that.boneMicGain)
      //
      //         const _destinationAudioElementMic2 = Tone.context.createMediaStreamDestination();
      //
      //         Tone.connect(that.boneMicGain, _destinationAudioElementMic2);
      //
      //         const destinationAudioElementMic2 = document.getElementById('playerMic2');
      //         destinationAudioElementMic2.srcObject = _destinationAudioElementMic2.stream;
      //
      //         // const destinationAudioElementMic2 = document.getElementById('playerMic2');
      //         // destinationAudioElementMic2.srcObject = that.boneMicGain;
      //
      //         const _destinationAudioElementMic = Tone.context.createMediaStreamDestination();
      //
      //         Tone.connect(that.airMicGain, _destinationAudioElementMic);
      //
      //         const destinationAudioElementMic = document.getElementById('playerMic');
      //         destinationAudioElementMic.srcObject = _destinationAudioElementMic.stream;
      //
      //         destinationAudioElementMic.setSinkId(that.selectedAir.deviceId);
      //         destinationAudioElementMic2.setSinkId(that.selectedBone.deviceId);
      //         // destinationAudioElementMic2.setSinkId(that.selectedBone.deviceId);
      //
      //         destinationAudioElementMic.play();
      //         destinationAudioElementMic2.play();
      //       } catch (e) {
      //         console.log(e)
      //       }
      //
      //     },
      //     function () {
      //       console.log("Error 003.")
      //     }
      //   );
      // }

      // console.log('prepareAudio');
      const sourceAudioElementAir = document.getElementById("sourceAir");
      console.log(this.selectedMusic);
      sourceAudioElementAir.src = this.selectedMusic[0].src;
      // sourceAudioElementAir.src = this.musicItem[0].src;

      const mediaElementSourceAir = Tone.context.createMediaElementSource(sourceAudioElementAir);
      const mediaElementDestAir = Tone.context.createMediaStreamDestination();
      const destinationAudioElementAir = document.getElementById("playerAir");

      const audioCtx = Tone.context;

      const filters = [];
      this.airFilter = [];

      this.airGain = new Tone.Volume(0);
      let lastObject = mediaElementSourceAir;

      Tone.connect(lastObject, this.airGain);

      lastObject = this.airGain;

      //
      // Tone.connect(lastObject, mediaElementDestAir);
      // destinationAudioElementAir.srcObject = mediaElementDestAir.stream;
      // await destinationAudioElementAir.setSinkId(this.selectedAir.deviceId);
      //
      // return;

      for (let i = 0; i < this.selectedFilter[0].filter.length; i += 1) {
        const biquadFilter = audioCtx.createBiquadFilter();
        biquadFilter.type = this.selectedFilter[0].filter[i].type;
        // biquadFilter.type = 'highpass';
        biquadFilter.frequency.value = this.selectedFilter[0].filter[i].frequency;
        biquadFilter.gain.value = this.selectedFilter[0].filter[i].gain;
        filters.push(biquadFilter);
        this.airFilter.push(biquadFilter);
      }

      for (let i = 0; i < filters.length; i += 1) {
        Tone.connect(lastObject, filters[i]);
        lastObject = filters[i];
      }

      this.panner = new Tone.Panner(this.selectedLeftVolume[0].pan);
      // this.reverb = new FX({
      //   effect: Tone.Reverb,
      //   options: {
      //     wet: this.optionreverb.parameter.wet,
      //     decay: this.optionreverb.parameter.decay
      //   },
      //   bypass: false
      // });
      this._3dpanner = new FX({
        effect: Tone.Panner3D,
        options: this.option3dpanner.parameter,
        bypass: false,
      });

      this.compressor = new Tone.Compressor(-30, 3);

      // this.analyzer = new Tone.Analyser({
      //   size: 32,
      //   type: 'fft',
      //   smoothing: 0.8
      // })

      this.analyzer = Tone.context.createAnalyser();
      this.analyzer.minDecibels = -100;
      this.analyzer.maxDecibels = -20;
      this.analyzer.smoothingTimeConstant = 0.5;
      this.analyzer.fftSize = 64;

      // Tone.connect(lastObject, mediaElementDestAir);

      Tone.connect(lastObject, this.panner);
      Tone.connect(this.panner, this._3dpanner);
      // Tone.connect(this._3dpanner, this.reverb);
      // Tone.connect(this.reverb, this.compressor);

      Tone.connect(this._3dpanner, this.compressor);

      Tone.connect(this.compressor, mediaElementDestAir);
      Tone.connect(this.compressor, this.analyzer);

      // const tremolo = new Tone.Tremolo(1, 1);
      // const gate = new Tone.Gate(-30, 0.2);
      // const phaser = new Tone.Phaser({
      //   frequency: 15,
      //   octaves: 5,
      //   baseFrequency: 1000,
      // });

      // // const panner = new Tone.Panner3D(0, 0, 0);
      // // const panner = new Tone.AutoPanner(4);
      // // const chorus = new Tone.Chorus(500, 20, 1);
      //
      // const timesTwo = new Tone.WaveShaper();
      // const compressor = new Tone.Compressor(-10, 5);

      // timesTwo.curve = this.distortionCurve(1);
      //
      // Tone.connect(reverb, compressor);

      // eslint-disable-next-line no-unused-vars

      // setTimeout(()=>{
      //   sample.togglePlayback();
      // }, 1000)
      // console.log(sample);
      // setInterval(() => {
      //   const decay = Math.floor(Math.random() * 3) * 10 + 0.01;
      //   reverb.decay = decay;
      //   console.log(decay);
      //   // timesTwo.curve = this.distortionCurve(Math.random() * 50);
      //   //
      //   // panner.setPosition(Math.random(), Math.random(), Math.random());
      // }, 500);
      // Tone.connect(mediaElementSourceAir, filter2);
      // Tone.connect(filter2, mediaElementDestAir);
      //
      // filter2.dispose();
      //
      // filter2.set({
      //   type: 'highshelf',
      //   frequency: 10000,
      //   rolloff: -24,
      // });
      // console.log(filter1.getFrequencyResponse());
      // console.log(filter2.getFrequencyResponse());
      // console.log(`${filter2.getFrequencyResponse()[0]},${filter2.getFrequencyResponse()[63]},
      // ${filter2.getFrequencyResponse()[126]}`);

      // console.log(filter3.getFrequencyResponse());
      // filter.chain(mediaElementDestAir);
      // Tone.connect(surround, reverb);
      // Tone.connect(reverb, mediaElementDestAir);

      destinationAudioElementAir.srcObject = mediaElementDestAir.stream;
      // destinationAudioElementAir.src = URL.createObjectURL(mediaElementDestAir);
      await destinationAudioElementAir.setSinkId(this.selectedAir.deviceId);

      // Tone.start();

      const sourceAudioElementBone = document.getElementById("sourceBone");
      sourceAudioElementBone.src = this.selectedMusic[0].src;
      const mediaElementSourceBone = Tone.context.createMediaElementSource(sourceAudioElementBone);
      const mediaElementDestBone = Tone.context.createMediaStreamDestination();
      const destinationAudioElementBone = document.getElementById("playerBone");

      this.boneGain = new Tone.Volume(0);

      Tone.connect(mediaElementSourceBone, this.boneGain);
      Tone.connect(this.boneGain, mediaElementDestBone);

      // Tone.connect(filter, mediaElementDestBone);

      destinationAudioElementBone.srcObject = mediaElementDestBone.stream;
      // destinationAudioElementBone.src = URL.createObjectURL(mediaElementDestBone);

      await destinationAudioElementBone.setSinkId(this.selectedBone.deviceId);
      //
      let min = -72;
      let max = 12;

      let targetGain1 = 0;

      if (this.airGainLevel === 50) {
        targetGain1 = 0;
      } else if (this.airGainLevel < 50) {
        targetGain1 = min * ((50 - this.airGainLevel) / 50);
      } else if (this.airGainLevel > 50) {
        targetGain1 = max * ((this.airGainLevel - 50) / 50);
      }
      this.airGain.volume.value = targetGain1;

      let targetGain2 = 0;

      let boneMin = -72;
      let boneMax = 12;

      if (this.boneGainLevel === 50) {
        targetGain2 = 0;
      } else if (this.boneGainLevel < 50) {
        targetGain2 = boneMin * ((50 - this.boneGainLevel) / 50);
      } else if (this.boneGainLevel > 50) {
        targetGain2 = boneMax * ((this.boneGainLevel - 50) / 50);
      }
      this.boneGain.volume.value = targetGain2;

      // console.log(sourceAudioElementAir.preservesPitch, sourceAudioElementBone.preservesPitch, destinationAudioElementAir.preservesPitch, destinationAudioElementBone.preservesPitch)
      sourceAudioElementAir.preservesPitch = true; //Chrome
      sourceAudioElementBone.preservesPitch = true; //Chrome
      destinationAudioElementAir.preservesPitch = true; //Chrome
      destinationAudioElementBone.preservesPitch = true; //Chrome

      sourceAudioElementAir.disableRemotePlayback = true;
      sourceAudioElementBone.disableRemotePlayback = true;
      destinationAudioElementAir.disableRemotePlayback = true;
      destinationAudioElementBone.disableRemotePlayback = true;

      // sourceAudioElementAir.play();
      // sourceAudioElementBone.play();
      destinationAudioElementAir.play();
      destinationAudioElementBone.play();
      // setInterval(()=>{
      //   console.log(destinationAudioElementAir.buffered)
      //     // destinationAudioElementAir.play();
      //     // destinationAudioElementBone.play();
      // }, 1000)

      clearInterval(this.sync);
      // this.sync = setInterval(async () => {
      //   const that = this;
      //   const delay = that.selectedBoneLatency[0].latency / 1000;
      //   const margin = 0.005;
      //   const el1 = sourceAudioElementBone;
      //   const el2 = sourceAudioElementAir;
      //   const diff = el1.currentTime - el2.currentTime;
      //   // console.log('delay?', delay);
      //   // console.log('margin?', margin);
      //   // console.log(delay, diff);
      //   if (Math.abs(diff) <= margin && delay < 0.005) {
      //     // console.log('1', that.successCount)
      //     el1.play();
      //     el2.play();
      //     that.enduranceCount = 0;
      //     that.successCount += 1;
      //     if (that.successCount > 10) {
      //       console.log('clear!!!!!1', diff);
      //
      //       destinationAudioElementAir.pause();
      //       destinationAudioElementBone.pause();
      //
      //       setTimeout(() => {
      //         destinationAudioElementAir.play();
      //         destinationAudioElementBone.play();
      //       }, 2000);
      //       clearInterval(that.sync);
      //       // setInterval(() => {
      //       //   const diff2 = el1.currentTime - el2.currentTime;
      //       //   console.log('time diff1', diff2);
      //       // }, 500);
      //     }
      //   } else if (diff > delay + margin) {
      //     // console.log('2')
      //     that.successCount = 0;
      //     that.enduranceCount += 1;
      //     if (that.enduranceCount > 10) {
      //       console.log('p1 pause', diff);
      //       el1.pause();
      //       el2.play();
      //       that.enduranceCount = 0;
      //     }
      //   } else if (diff < delay - margin) {
      //     // console.log('3')
      //     that.enduranceCount += 1;
      //     that.successCount = 0;
      //     if (that.enduranceCount > 10) {
      //       console.log('p2 pause', diff);
      //       el1.play();
      //       el2.pause();
      //       that.enduranceCount = 0;
      //     }
      //   } else {
      //     // console.log('4')
      //     el1.play();
      //     el2.play();
      //     that.enduranceCount = 0;
      //     that.successCount += 1;
      //     if (that.successCount > 10) {
      //       console.log('2clear!!!!!', diff);
      //
      //       destinationAudioElementAir.pause();
      //       destinationAudioElementBone.pause();
      //
      //       setTimeout(() => {
      //         destinationAudioElementAir.play();
      //         destinationAudioElementBone.play();
      //       }, 2000);
      //       clearInterval(that.sync);
      //       // setInterval(() => {
      //       //   const diff2 = el1.currentTime - el2.currentTime;
      //       //   console.log('time diff', diff2);
      //       // }, 500);
      //     }
      //   }
      // }, 1);
    },
    /* eslint-disable */
    distortionCurve(amount) {
      const k = typeof amount === "number" ? amount : 50;
      const nSamples = 44100;
      const curve = new Float32Array(nSamples);
      const deg = Math.PI / 180;
      let i = 0;
      let x;
      for (; i < nSamples; ++i) {
        x = (i * 2) / nSamples - 1;
        curve[i] = ((3 + k) * x * 20 * deg) / (Math.PI + k * Math.abs(x));
      }
      return curve;
    },
    initDecibelList(length) {
      this.decibelList = [];
      for (let i = 0; i < length; i++) {
        this.decibelList.push([]);
      }
    },
    addBarSpans() {
      const bars = document.getElementsByClassName("equalizer-bar");

      let html = "";
      for (let j = 0; j < MAX_BAR_HEIGHT; j++) {
        html += "<span></span>";
      }

      for (let i = 0; i < bars.length; i++) {
        bars[i].innerHTML = html;
      }
    },
    getActiveSpans(spans) {
      let counter = 0;

      for (let i = 0; i < spans.length; i++) {
        if (spans[i].style.opacity > 0) counter++;
      }

      return counter;
    },
    getRandomHeight(maxBarHeight) {
      return Math.round(Math.random() * (maxBarHeight - 1)) + 1;
    },
    getNotRandomHeight(signalLevel, signalMin, signalMax, maxBarHeight) {
      let scaledSignal = Math.min(Math.max(signalMin, signalLevel), signalMax);

      let ratio = (scaledSignal - signalMin) / (signalMax - signalMin);
      return Math.round(ratio * (maxBarHeight - 1)) + 1;
    },
    getListAverage(list) {
      if (list.length > 0) {
        let average = 0;
        for (let i = 0; i < list.length; i++) {
          average += list[i] / list.length;
        }
        return average;
      }
      return 0;
    },
    setRandomBars() {
      const bars = document.getElementsByClassName("equalizer-bar");

      if (!this.analyzer) return;

      if(this.isSetRandomBar) return;

      let bufferLength = this.analyzer.frequencyBinCount;
      let dataArray = new Float32Array(32);
      let dataArray2 = new Uint8Array(bufferLength);

      this.analyzer.getFloatFrequencyData(dataArray);
      this.analyzer.getByteFrequencyData(dataArray2);

      let output = dataArray2;
      // console.log(output)

      for (let i = 0; i < output.length; i++) {
        this.decibelList[i].push(output[i]);
        if (this.decibelList[i].length > 100) {
          this.decibelList[i].shift();
        }
      }

      for (let i = 0; i < bars.length; i++) {
        if (output[i] > this.getListAverage(this.decibelList[i]) * 2) {
          if (this.optionreverb.random && !this.isChangeReverb && this.optionreverb.active) {
            if (Math.random() > 1 - this.optionreverb.probability) {
              let decay = Math.floor(Math.random() * 40);
              let wet = Math.random();
              this.optionreverb.parameter.wet = wet;
              this.optionreverb.parameter.decay = decay;
              this.isChangeReverb = true;
              setTimeout(() => {
                this.isChangeReverb = false;
              }, 1000);
            }
          }
          if (this.option3dpanner.random && !this.isChangePanner3d && this.option3dpanner.active) {
            if (Math.random() > 1 - this.option3dpanner.probability) {
              let a = -0.5 + Math.random();
              let b = -2 + Math.random() * 4;
              let c = -2 + Math.random() * 4;
              this._3dpanner.effect.setPosition(a, b, c);

              let targetValue = a;

              let count = 0;
              clearInterval(this.smoothPanner);

              let vue = this;
              (this.smoothPanner = setInterval(() => {
                let current = vue.option3dpanner.parameter.positionX;
                vue.option3dpanner.parameter.positionX = current + (targetValue - current) * 0.2;
                // console.log(
                //   "this.option3dpanner.parameter.positionX",
                //   vue.option3dpanner.parameter.positionX
                // );
                // console.log("current", current, "targetValue", targetValue);

                count += 1;
                if (count > 5) {
                  clearInterval(vue.smoothPanner);
                }
              }, 33)),
                (this.option3dpanner.parameter.positionY = b);
              this.option3dpanner.parameter.positionZ = c;
              this.isChangePanner3d = true;
              setTimeout(() => {
                this.isChangePanner3d = false;
              }, this.option3dpanner.randomDelay);
            }
          }
        }

        let spans = bars[i].getElementsByTagName("span");
        let activeSpanCount = this.getActiveSpans(spans);
        let newHeight = this.getNotRandomHeight(output[i], 0, 255, MAX_BAR_HEIGHT);

        for (let j = 0; j < spans.length; j++) {
          if (newHeight > activeSpanCount) {
            spans[j].style.opacity = "1";
          } else if (j > newHeight) {
            spans[j].style.opacity = "0";
          }
        }
      }
    },
  },
  data() {
    return {
      localFile: null,
      diffInterval: null,
      isSyncing: false,
      syncSuccess: false,
      isEnded: false,
      trainingId: '',
      isTraining: false,
      trainingTime: 0,
      trainingTimeInSeconds: 0,
      isSeeking: false,
      smoothPanner: null,
      analyzer: null,
      pannerInterval: null,
      reverbInterval: null,
      panner3dInterval: null,
      micDevices: [],
      _3dpanner: null,
      panner: null,
      reverb: null,
      compressor: null,
      airEffect: [],
      airFilter: [],
      boneFilter: [],
      airLowFilter: null,
      airHighFilter: null,
      airGain: null,
      airSurround: null,
      airReverb: null,
      airEnvelop: null,
      gain: null,
      surround: null,
      envelope: null,
      sync: null,
      ready: false,
      selectedMusic: [],
      selectedFilter: [],
      selectedAir: "",
      selectedBone: "",
      selectedMic: "",
      boneLatency: 0,
      airLeftVolume: 100,
      useDefaultMusic: true,
      e6: 1,
      musicHeader: [
        {
          text: "제목",
          align: "center",
          value: "filename",
        },
        {
          text: "재생 시간",
          align: "center",
          value: "playtime",
        },
        {
          text: "파일 크기",
          align: "center",
          value: "filesize",
        },
        {
          text: "파일 확장자",
          align: "center",
          value: "mime",
        },
      ],
      musicItem: [],
      filterHeader: [
        {
          text: "타입",
          align: "center",
          value: "type",
        },
        // {
        //   text: "모드",
        //   align: "center",
        //   value: "description",
        // },
      ],
      filterItem_2: [
        {
          type: "M1",
          description:
            "750Hz 이하에서 옥타브당 6dB씩 음량 감소, 4,000Hz 이상에서 옥타브당 12dB씩 음량 감소",
          filter: [
            {
              type: "lowshelf",
              frequency: 375,
              gain: -6,
            },
            {
              type: "highshelf",
              frequency: 2000,
              gain: -12,
            },
          ],
        },
        {
          type: "M2",
          description:
            "750Hz 이하에서 옥타브당 12dB씩 음량 감소, 4,000Hz 이상에서 옥타브당 24dB씩 음량 감소",
          filter: [
            {
              type: "lowshelf",
              frequency: 375,
              gain: -12,
            },
            {
              type: "highshelf",
              frequency: 2000,
              gain: -24,
            },
          ],
        },
        {
          type: "M3",
          description:
            "750Hz 이하에서 옥타브당 24dB씩 음량 감소, 4,000Hz 이상에서 옥타브당 24dB씩 음량 감소",
          filter: [
            {
              type: "lowshelf",
              frequency: 375,
              gain: -24,
            },
            {
              type: "highshelf",
              frequency: 2000,
              gain: -24,
            },
          ],
        },
        {
          type: "L1",
          description: "2,000Hz 이상에서 옥타브당 24dB씩 음량 감소",
          filter: [
            {
              type: "highshelf",
              frequency: 1000,
              gain: -24,
            },
          ],
        },
        {
          type: "L2",
          description: "2,000Hz 이상에서 옥타브당 48dB씩 음량 감소",
          filter: [
            {
              type: "highshelf",
              frequency: 1000,
              gain: -48,
            },
          ],
        },
        {
          type: "L3",
          description: "1,000Hz 이상에서 옥타브당 24dB씩 음량 감소",
          filter: [
            {
              type: "highshelf",
              frequency: 500,
              gain: -24,
            },
          ],
        },
        {
          type: "L4",
          description: "1,000Hz 이상에서 옥타브당 48dB씩 음량 감소",
          filter: [
            {
              type: "highshelf",
              frequency: 500,
              gain: -48,
            },
          ],
        },
      ],
      filterItem_1: [
        {
          type: "F0",
          description: "No Filtering",
          filter: [],
        },
        {
          type: "H1",
          description: "1,000Hz 이하에서 옥타브당 48dB씩 음량 감소",
          filter: [
            {
              type: "lowshelf",
              frequency: 500,
              gain: -48,
            },
          ],
        },
        {
          type: "H2",
          description: "2,000Hz 이하에서 옥타브당 48dB씩 음량 감소",
          filter: [
            {
              type: "lowshelf",
              frequency: 1000,
              gain: -48,
            },
          ],
        },
        {
          type: "H3",
          description: "4,000Hz 이하에서 옥타브당 48dB씩 음량 감소",
          filter: [
            {
              type: "lowshelf",
              frequency: 2000,
              gain: -48,
            },
          ],
        },
        {
          type: "H4",
          description:
            "4,000Hz 이하에서 옥타브당 24dB씩 음량 감소, 10,000Hz 중심으로 24dB 음량 증가",
          filter: [
            {
              type: "lowshelf",
              frequency: 2000,
              gain: -24,
            },
            {
              type: "peaking",
              frequency: 10000,
              gain: 12,
            },
          ],
        },
        {
          type: "H5",
          description:
            "4,000Hz 이하에서 옥타브당 48dB씩 음량 감소, 10,000Hz 중심으로 48dB 음량 증가",
          filter: [
            {
              type: "lowshelf",
              frequency: 2000,
              gain: -48,
            },
            {
              type: "peaking",
              frequency: 10000,
              gain: 48,
            },
          ],
        },
        {
          type: "H6",
          description:
            "4,000Hz 이하에서 옥타브당 60dB씩 음량 감소, 10,000Hz 중심으로 60dB 음량 증가",
          filter: [
            {
              type: "lowshelf",
              frequency: 2000,
              gain: -60,
            },
            {
              type: "peaking",
              frequency: 10000,
              gain: 60,
            },
          ],
        },
      ],
      audioDevice: ["device1", "device2"],
      effectHeader: [
        {
          text: "효과",
          align: "center",
          value: "name",
          width: "20%",
        },
        {
          text: "랜덤화 강도",
          align: "center",
          value: "randomizeLevel",
          width: "40%",
        },
        {
          text: "랜덤화 주기",
          align: "center",
          value: "randomizeInterval",
          width: "40%",
        },
      ],
      requiredHeader: [
        {
          text: "효과",
          align: "center",
          value: "name",
          width: "30%",
        },
        {
          text: "조절",
          align: "center",
          value: "value",
          width: "70%",
        },
      ],
      requiredItem: [
        {
          name: "공기전도 좌측 볼륨",
          value: 100,
        },
        {
          name: "골전도 지연시간",
          value: 0,
        },
      ],
      effectItem: [
        {
          name: "Surround",
          randomizeLevel: 50,
          randomizeInterval: 50,
        },
        {
          name: "Reverb",
          randomizeLevel: 50,
          randomizeInterval: 50,
        },
      ],
      selectedEffect: [],
      leftVolumeHeader: [
        {
          text: "타입",
          value: "type",
          align: "center",
        },
      ],
      leftVolumeItem_1: [
        {
          type: "V1",
          description: "좌측 볼륨 100%, 우측 볼륨 100%",
          pan: 0,
        },
        {
          type: "V2",
          description: "좌측 볼륨 90%, 우측 볼륨 100%",
          pan: 0.1,
        },
        {
          type: "V3",
          description: "좌측 볼륨 80%, 우측 볼륨 100%",
          pan: 0.2,
        },
        {
          type: "V4",
          description: "좌측 볼륨 70%, 우측 볼륨 100%",
          pan: 0.3,
        },
        {
          type: "V5",
          description: "좌측 볼륨 60%, 우측 볼륨 100%",
          pan: 0.4,
        },
        {
          type: "V6",
          description: "좌측 볼륨 50%, 우측 볼륨 100%",
          pan: 0.5,
        },
      ],
      leftVolumeItem_2: [
        {
          type: "V7",
          description: "좌측 볼륨 40%, 우측 볼륨 100%",
          pan: 0.4,
        },
        {
          type: "V8",
          description: "좌측 볼륨 30%, 우측 볼륨 100%",
          pan: 0.3,
        },
        {
          type: "V9",
          description: "좌측 볼륨 20%, 우측 볼륨 100%",
          pan: 0.2,
        },
        {
          type: "V10",
          description: "좌측 볼륨 10%, 우측 볼륨 100%",
          pan: 0.1,
        },
        {
          type: "V11",
          description: "좌측 볼륨 0%, 우측 볼륨 100%",
          pan: 1,
        },
      ],
      selectedLeftVolume: [],
      boneLatencyHeader: [
        {
          text: "타입",
          value: "type",
          align: "center",
        },
        // {
        //   text: "설명",
        //   value: "description",
        //   align: "center",
        // },
      ],
      boneLatencyItem_1: [
        {
          type: "P0",
          description: "공기전도와 골전도 동시 재생",
          latency: 0,
        },
        {
          type: "P1",
          description: "공기전도 재생장치 25ms 느리게 재생",
          latency: 25,
        },
        {
          type: "P2",
          description: "공기전도 재생장치 50ms 느리게 재생",
          latency: 50,
        },
        {
          type: "P3",
          description: "공기전도 재생장치 75ms 느리게 재생",
          latency: 75,
        },
        {
          type: "P4",
          description: "공기전도 재생장치 100ms 느리게 재생",
          latency: 100,
        },
        {
          type: "P5",
          description: "공기전도 재생장치 125ms 느리게 재생",
          latency: 125,
        },
      ],
      boneLatencyItem_2: [
        {
          type: "P6",
          description: "공기전도 재생장치 150ms 느리게 재생",
          latency: 150,
        },
        {
          type: "P7",
          description: "공기전도 재생장치 175ms 느리게 재생",
          latency: 175,
        },
        {
          type: "P8",
          description: "공기전도 재생장치 200ms 느리게 재생",
          latency: 200,
        },
        {
          type: "P9",
          description: "공기전도 재생장치 225ms 느리게 재생",
          latency: 225,
        },
        {
          type: "P10",
          description: "공기전도 재생장치 250ms 느리게 재생",
          latency: 250,
        },
      ],
      boneLatencyItem_3: [
        {
          type: "P11",
          description: "공기전도 재생장치 500ms 느리게 재생",
          latency: 500,
        },
        {
          type: "P12",
          description: "공기전도 재생장치 1,000ms 느리게 재생",
          latency: 1000,
        },
        {
          type: "P13",
          description: "공기전도 재생장치 1,500ms 느리게 재생",
          latency: 1500,
        },
        {
          type: "P14",
          description: "공기전도 재생장치 2,000ms 빠르게 재생",
          latency: 2000,
        },
        {
          type: "P15",
          description: "공기전도 재생장치 2,500ms 지연 재생",
          latency: 2500,
        },
      ],
      selectedBoneLatency: [],
      optionreverb: {
        parameter: { decay: 10, wet: 0.5 },
        active: false,
        random: false,
        interval: 10,
        probability: 0.03,
      },
      option3dpanner: {
        parameter: { positionX: 0, positionY: 0, positionZ: 0 },
        active: true,
        random: true,
        interval: 2,
        probability: 0.1,
        randomDelay: 1500,
      },
      enduranceCount: 0,
      successCount: 0,
      reverbRandomItem: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      pannerRandomItem: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      airGain: null,
      boneGain: null,
      airGainLevel: 50,
      boneGainLevel: 50,
      airMicGain: null,
      boneMicGain: null,
      airMicGainLevel: 0,
      boneMicGainLevel: 0,
      isChange: false,
      isChangeReverb: false,
      isChangePanner3d: false,
      decibelList: [],
      setRandomBarInterval: null,
      serverCheckInterval: null,
      screenLock: false,
      disableLockDialog: false,
      password: "",
      isSetRandomBar: false,
    };
  },
};
</script>

<style>
.v-slider--vertical {
  min-height: 40px !important;
  height: 40px !important;
  max-height: 40px !important;
}

.equalizer {
  position: absolute;
  bottom: 27%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 2%;
  width: 60%;
  margin-top: 0rem;
}

.equalizer-bar {
  width: 2%;
  margin: 0 3px;
  display: flex;
  flex-direction: column-reverse;
}

.equalizer-bar span {
  display: block;
  height: 2vh;
  border-radius: 2px;
  width: 100%;
  margin: 2px 0;
  background-color: #fff;
  transition: 0.2s ease all;
  opacity: 1;
}
.equalizer-bar:nth-child(1) span {
  background: #fc0127;
}
.equalizer-bar:nth-child(2) span {
  background: #fc0127;
}
.equalizer-bar:nth-child(3) span {
  background: #ffa726;
}
.equalizer-bar:nth-child(4) span {
  background: #ffa726;
}
.equalizer-bar:nth-child(5) span {
  background: #ffca28;
}
.equalizer-bar:nth-child(6) span {
  background: #ffca28;
}
.equalizer-bar:nth-child(7) span {
  background: #ffee58;
}
.equalizer-bar:nth-child(8) span {
  background: #ffee58;
}
.equalizer-bar:nth-child(9) span {
  background: #d4e157;
}
.equalizer-bar:nth-child(10) span {
  background: #d4e157;
}
.equalizer-bar:nth-child(11) span {
  background: #9ccc65;
}
.equalizer-bar:nth-child(12) span {
  background: #9ccc65;
}
.equalizer-bar:nth-child(13) span {
  background: #66bb6a;
}
.equalizer-bar:nth-child(14) span {
  background: #66bb6a;
}
.equalizer-bar:nth-child(15) span {
  background: #26a69a;
}
.equalizer-bar:nth-child(16) span {
  background: #26a69a;
}
.equalizer-bar:nth-child(17) span {
  background: #26c6da;
}
.equalizer-bar:nth-child(18) span {
  background: #26c6da;
}
.equalizer-bar:nth-child(19) span {
  background: #29b6f6;
}
.equalizer-bar:nth-child(20) span {
  background: #29b6f6;
}
.equalizer-bar:nth-child(21) span {
  background: #42a5f5;
}
.equalizer-bar:nth-child(22) span {
  background: #42a5f5;
}
.equalizer-bar:nth-child(23) span {
  background: #5c6bc0;
}
.equalizer-bar:nth-child(24) span {
  background: #5c6bc0;
}
.equalizer-bar:nth-child(25) span {
  background: #7e57c2;
}
.equalizer-bar:nth-child(26) span {
  background: #7e57c2;
}
.equalizer-bar:nth-child(27) span {
  background: #ab47bc;
}
.equalizer-bar:nth-child(28) span {
  background: #ab47bc;
}
.equalizer-bar:nth-child(29) span {
  background: #616161;
}
.equalizer-bar:nth-child(30) span {
  background: #616161;
}
.equalizer-bar:nth-child(31) span {
  background: #212121;
}
.equalizer-bar:nth-child(32) span {
  background: #212121;
}
</style>
