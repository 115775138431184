import Vue from 'vue';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';
// eslint-disable-next-line import/order
import { VueMaskDirective } from 'v-mask';

Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = true;

new Vue({
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
