<template>
  <v-sheet>
  <v-row>
      <v-col col="12">
        <v-row>
          <v-col cols="3" class="text-center">
            <br>
            <b>{{centerName}}</b>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="20"
          sort-by="createdAtOrigin"
          sort-desc
          class="elevation-1"
          :search="search"
        ></v-data-table>
      </v-col>
  </v-row>
  </v-sheet>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UsageTable',
  props: {
    centerName: {
      type: String,
      default: '센터 이름',
    },
    centerId: {
      type: String,
    },
  },
  async mounted() {
    const response = await axios.get(`https://listening-able.visualcamp.net/api/center/${this.centerId}/charge`, { withCredentials: true });
    console.log(this.centerId);
    if (response.status === 200 && response.data.charges) {
      console.log(response.data.charges);
      this.items = response.data.charges;

      this.items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign,prefer-destructuring
        item.createdAtOrigin = JSON.parse(JSON.stringify(item.createdAt));
        // eslint-disable-next-line no-param-reassign,prefer-destructuring
        item.createdAt = new Date(item.createdAt).toLocaleString().split(' GMT')[0];
      });
    }
  },
  methods: {
    async reload() {
      const response = await axios.get(`https://listening-able.visualcamp.net/api/center/${this.centerId}/charge`, { withCredentials: true });
      console.log(this.centerId);
      if (response.status === 200 && response.data.charges) {
        console.log(response.data.charges);
        this.items = response.data.charges;

        this.items.forEach((item) => {
          // eslint-disable-next-line no-param-reassign,prefer-destructuring
          item.createdAtOrigin = JSON.parse(JSON.stringify(item.createdAt));
          // eslint-disable-next-line no-param-reassign,prefer-destructuring
          item.createdAt = new Date(item.createdAt).toLocaleString().split(' GMT')[0];
        });
      }
    },
  },
  data() {
    return {
      search: '',
      items: [
        {
          date: '2022/08/31 12:30',
          type: '시간 부여',
          user: '관리자',
          timeChange: '+ 3,000',
          timeRemain: '3,000',
        },
        {
          date: '2022/09/01 10:10',
          type: '시간 차감',
          user: '관리자',
          timeChange: '- 2,000',
          timeRemain: '1,000',
        },
        {
          date: '2022/09/02 16:37',
          type: '훈련 사용',
          user: '김세종',
          timeChange: '- 30',
          timeRemain: '970',
        },
        {
          date: '2022/09/03 11:30',
          type: '훈련 사용',
          user: '박세근',
          timeChange: '- 370',
          timeRemain: '600',
        },
      ],
      headers: [
        {
          text: '일시',
          align: 'center',
          value: 'createdAt',
        },
        {
          text: '종류',
          align: 'center',
          value: 'type',
        },
        {
          text: '대상',
          align: 'center',
          value: 'name',
        },
        {
          text: '잔여 시간 변화(분)',
          align: 'center',
          value: 'timeDifference',
        },
        {
          text: '잔여 시간(분)',
          align: 'center',
          value: 'afterRemainTime',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
