<template>
  <v-row>
    <v-dialog
      v-model="deleteDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          주의가 필요한 작업
        </v-card-title>

        <v-card-text>
          삭제 후 정보를 되돌릴 수 없습니다. 진행하시겠습니까?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteDialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="red"
            text
            @click="deleteItem()"
          >
            삭제
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-col
      cols="9"
    >
      <v-sheet
        class="text-right"
        rounded="lg"
        min-height="50vh"
      >
        <v-row>
          <v-col cols="3">
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn class="ma-3" large outlined color="black"
                   :disabled="mode !== 0"
                   @click="enrollNewUser()">
              신규 센터 등록
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="20"
          sort-by="calories"
          class="elevation-1"
        >
          <template v-slot:item.history="{ item }">
            <v-btn
              small
              outlined
              color="black"
              :disabled="mode !== 0"
              @click="history(item)">
              이력
            </v-btn>
          </template>
          <template v-slot:item.modify="{ item, index }">
            <v-btn
              small
              outlined
              color="warning"
              :disabled="mode !== 0"
              @click="editItem(item, index)">
              수정
            </v-btn>
          </template>
          <template v-slot:item.delete="{ item, index }">
            <v-btn
              small
              outlined
              color="error"
              :disabled="mode !== 0"
              @click="loadDeleteDialog(index)">
              삭제
            </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
    <v-col
      cols="3"
    >
      <v-sheet
        v-if="mode === 1 && editedItem"
        class="text-center"
        rounded="lg"
        min-height="50vh"
      >
        <h2>센터 정보 수정</h2>
        <v-text-field
          class="pa-2"
          dense
          label="센터명"
          outlined
          hide-details
          v-model="editedItem.centerName"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="비밀번호, 변경시에만 입력"
          outlined
          hide-details
          type="password"
          v-model="editedItem.password"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="비밀번호 확인, 변경시에만 입력"
          outlined
          hide-details
          type="password"
          v-model="editedItem.passwordConfirm"
        ></v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              class="pa-2"
              value="100"
              dense
              label="부여 시간(분)"
              outlined
              hide-details
              type="number"
              v-model="timeDiff"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              class="pa-2"
              value="100"
              dense
              label="변경 전 잔여시간(분)"
              outlined
              hide-details
              readonly
              disabled
              v-model="editedItem.remainTime"
            ></v-text-field>
          </v-col>
          <v-icon>
            mdi-arrow-right
          </v-icon>
          <v-col>
            <v-text-field
              class="pa-2"
              dense
              label="변경 후 잔여시간(분)"
              outlined
              hide-details
              :value="futureRemainTime"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn class="ma-3" large outlined color="primary" @click="editItemConfirm">
          수정
        </v-btn>
        <v-btn class="ma-3" large outlined color="error" @click=resetMode>
          취소
        </v-btn>
      </v-sheet>
      <v-sheet
        v-if="mode === 2 && editedItem"
        class="text-center"
        rounded="lg"
        min-height="50vh"
      >
        <h2>신규 센터 등록</h2>
        <v-text-field
          class="pa-2"
          label="센터명"
          dense
          outlined
          :rules="[() => !!editedItem.centerName || '센터 이름을 입력해주세요']"
          v-model="editedItem.centerName"
          validate-on-blur
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="비밀번호"
          outlined
          type="password"
          :rules="[() => !!editedItem.password || '비밀번호를 입려해주세요']"
          v-model="editedItem.password"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="비밀번호 확인"
          outlined
          type="password"
          :rules="[() => !!editedItem.password &&
          editedItem.password === editedItem.passwordConfirm || '비밀번호가 같지 않습니다.']"
          v-model="editedItem.passwordConfirm"
        ></v-text-field>
        <v-btn class="ma-3" large outlined color="primary"
               :disabled="!editedItem.centerName || !editedItem.password
                || (editedItem.password !== editedItem.passwordConfirm)" @click="enrollItemConfirm">
          등록
        </v-btn>
        <v-btn class="ma-3" large outlined color="error" @click="resetMode">
          취소
        </v-btn>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserTable',
  async mounted() {
    const response = await axios.get('https://listening-able.visualcamp.net/api/center', { withCredentials: true });
    if (response.status === 200 && response.data.centers) {
      this.items = response.data.centers;
    }
    console.log(response);
  },
  methods: {
    async enrollItemConfirm() {
      const response = await axios.post('https://listening-able.visualcamp.net/api/center', this.editedItem, { withCredentials: true });
      if (response.status === 200 && response.data.center) {
        this.mode = 0;
        this.editedItem = null;
        this.items.push(response.data.center);
      }
    },
    async editItemConfirm() {
      // eslint-disable-next-line operator-assignment
      this.editedItem.remainTime = parseInt(this.editedItem.remainTime, 10)
        + parseInt(this.timeDiff, 10);
      console.log(this.editedItem);
      // eslint-disable-next-line no-underscore-dangle
      const response = await axios.post(`https://listening-able.visualcamp.net/api/center/${this.editedItem._id}`, this.editedItem, { withCredentials: true });
      if (response.status === 200 && response.data.center) {
        this.mode = 0;
        this.editedItem = null;
        this.items[this.editedItemIndex] = response.data.center;
        this.items = JSON.parse(JSON.stringify(this.items));
        this.mode = 0;
        this.editedItem = null;
        this.editedItemIndex = -1;
      }
    },
    loadDeleteDialog(index) {
      this.deleteDialog = true;
      this.deletedItemIndex = index;
    },
    enrollNewUser() {
      this.mode = 2;
      this.editedItem = {
        remainTime: 0,
        userCount: 0,
        activeUserCount: 0,
      };
    },
    editItem(item, index) {
      this.mode = 1;
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.editedItemIndex = index;
      this.timeDiff = 0;
      this.futureRemainTime = item.remainTime;
    },
    // eslint-disable-next-line no-unused-vars
    async deleteItem() {
      if (this.deletedItemIndex > -1) {
        // eslint-disable-next-line no-underscore-dangle
        const response = await axios.delete(`https://listening-able.visualcamp.net/api/center/${this.items[this.deletedItemIndex]._id}`, { withCredentials: true });
        console.log(response);
        if (response.status === 200) {
          this.items.splice(this.deletedItemIndex, 1);
          this.deleteDialog = false;
          this.deletedItemIndex = -1;
        }
      }
    },
    resetMode() {
      this.mode = 0;
      this.editedItem = null;
      this.editedItemIndex = -1;
      this.historyItem = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    history(item) {
      this.$emit('history', item);
    },
  },
  computed: {
    headers() {
      return [
        {
          text: '소속 센터명',
          align: 'center',
          value: 'centerName',
        },
        {
          text: '센터 코드',
          align: 'center',
          value: 'centerCode',
        },
        {
          text: '전체 등록자 ',
          align: 'center',
          value: 'userCount',
        },
        {
          text: '활성 등록자 수',
          align: 'center',
          value: 'activeUserCount',
        },
        {
          text: '잔여 시간(분)',
          align: 'center',
          value: 'remainTime',
        },
        {
          text: '부여 이력', value: 'history', sortable: false, align: 'center',
        },
        {
          text: '센터 정보 변경', value: 'modify', sortable: false, align: 'center',
        },
        {
          text: '센터 삭제', value: 'delete', sortable: false, align: 'center',
        },
      ];
    },
  },
  props: {
    isAdmin: Boolean,
  },
  data() {
    return {
      historyItem: null,
      search: '',
      deleteDialog: false,
      deletedItemIndex: -1,
      editedItem: null,
      editedItemIndex: -1,
      mode: 0,
      menu2: false,
      items: [],
      futureRemainTime: 0,
      timeDiff: 0,
    };
  },
  watch: {
    timeDiff: {
      deep: true,
      handler(val) {
        console.log('hiih', val);
        this.futureRemainTime = parseInt(this.editedItem.remainTime, 10)
          + parseInt(this.timeDiff, 10);
      },
    },
  },
};
</script>

<style scoped>

</style>
