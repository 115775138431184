<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>리스닝 에이블 로그인</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    name="login"
                    label="기관 코드"
                    type="text"
                    prefix="LA -"
                    v-model="centerCode"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    name="password"
                    label="비밀번호"
                    type="password"
                    v-model="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="pr-5">
                <v-spacer></v-spacer>
<!--                <v-btn large outlined color="primary" @click="adminLogin">관리자 로그인</v-btn>-->
                <v-btn large outlined color="primary" @click="login">로그인</v-btn>

              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  props: {
    source: String,
  },
  data() {
    return {
      centerCode: '',
      password: '',
    };
  },
  methods: {
    async login() {
      const response = await axios.post('https://listening-able.visualcamp.net/api/auth/signin', {
        centerCode: `LA-${this.centerCode}`,
        password: this.password,
      }, { withCredentials: true });
      if (response.status === 200 && response.data.result === true) {
        this.$emit('login');
      }
      this.password = '';
      this.centerCode = '';
    },
    adminLogin() {
      this.$emit('adminLogin');
    },
  },
};
</script>

<style></style>
