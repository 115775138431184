<template>
  <v-app id="inspire">
    <v-dialog
      v-model="popupDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          잔여 시간 알림
        </v-card-title>

        <v-card-text>
          잔여시간이 {{remainTime}}분 남았습니다.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="popupDialog = false"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog
      v-if="changePasswordDialog"
      v-model="changePasswordDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          비밀번호 변경
        </v-card-title>
        <v-text-field
          class="pa-2"
          dense
          label="현재 비밀번호"
          outlined
          type="password"
          :rules="[() => !!beforePassword || '현재 비밀번호를 입려해주세요']"
          v-model="beforePassword"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="변경할 비밀번호"
          outlined
          type="password"
          :rules="[() => !!password || '변경할 비밀번호를 입려해주세요']"
          v-model="password"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="비밀번호 확인"
          outlined
          type="password"
          :rules="[() => !!password &&
          password === passwordConfirm || '변경할 비밀번호가 같지 않습니다.']"
          v-model="passwordConfirm"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="!password || (password !== passwordConfirm)"
            @click="changePassword"
          >
            변경
          </v-btn>
          <v-btn
            color="red"
            text
            @click="changePasswordDialog = false"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-app-bar
      v-if="isLogin && !isTesting"
      app
      color="white"
    >
      <v-btn outlined color="primary" class="pl-3 pr-3"
             @click="goToNoticeBoard">
        <v-icon>
          mdi-bulletin-board
        </v-icon>
        공지사항
      </v-btn>
      <v-btn
        class="ml-3"
        color="error"
        @click="openChangePasswordDialog"
        outlined
      >
        비밀번호 변경
      </v-btn>
      <v-tabs
        v-model="currentLink"
        centered
        class="ml-n5"
        color="grey darken-1"
      >
        <v-tab
          v-for="link in links"
          :key="link"
        >
          {{ link }}
        </v-tab>
      </v-tabs>
      <v-btn outlined color="black" class="mr-3" @click="resetAndgoTo1()">
        <v-icon class="">
          mdi-time
        </v-icon>
        잔여 시간: {{remainTime}}분
      </v-btn>

      <v-btn
        class="mr-3"
        color="error"
        @click="goToHome"
        outlined
      >
        로그아웃
      </v-btn>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container fluid>
        <Login v-if="!isLogin"
        @login="login"
        @adminLogin="adminLogin"></Login>
        <Player v-if="isTesting && isLogin" :center-id="adminCenterId"
                @endTraining="endTraining"
        @goBack="goBack"
        :user-code="trainingUserCode"
        :user-name="trainingUserName"
        :user-id="trainingUserId"
        :remain-time="remainTime"
        />
        <UserTable :is-admin="isAdmin" :center-id="adminCenterId" v-if="currentLink === 0 && isLogin
        && !isTesting"
        @startTraining="startTraining"
        :center-list="centerList"/>
        <CenterTable :is-admin="isAdmin" v-if="currentLink
        === 2 && isLogin && isAdmin && !isTesting"
        @history="history"/>
        <UsageTable v-if="currentLink === 1 && isLogin && !isTesting"
                    :center-name="centerName" :center-id="centerId"
        ref="usageTable"/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import Player from './components/Player.vue';
import Login from './components/Login.vue';
import CenterTable from './components/CenterTable.vue';
import UserTable from './components/UserTable.vue';
import UsageTable from './components/UsageTable.vue';
// import HelloWorld from './components/HelloWorld.vue';

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Player, Login, CenterTable, UserTable, UsageTable,
  },
  async mounted() {
    await this.me();
  },
  data: () => ({
    centerName: '',
    adminCenterName: '',
    adminCenterId: '',
    centerId: '',
    remainTime: 0,
    isLogin: false,
    isAdmin: false,
    isTesting: false,
    usageItem: null,
    popupDialog: false,
    currentLink: '',
    centerList: [],
    changePasswordDialog: false,
    beforePassword: '',
    password: '',
    passwordConfirm: '',
    trainingUserName: '',
    trainingUserCode: '',
    trainingUserId: '',
  }),
  computed: {
    links() {
      if (this.isAdmin) {
        return [
          '회원 관리',
          '사용 이력 관리',
          '센터 관리',
        ];
      }
      return [
        '회원 관리',
        '사용 이력 관리',
      ];
    },
  },
  watch: {
    async currentLink(val) {
      await this.updateRemainTimeOnly();
      if (val !== 1) {
        await this.me();
        this.reset();
      } else {
        // this.me();
      }
    },
  },
  methods: {
    async changePassword() {
      try {
        const response = await axios.post('https://listening-able.visualcamp.net/api/auth/password',
          {
            beforePassword: this.beforePassword,
            password: this.password,
            passwordConfirm: this.passwordConfirm,
          },
          { withCredentials: true });
        if (response.status === 200) {
          alert('비밀번호 변경 성공');
        } else {
          alert('비밀번호 변경 실패');
        }
        this.changePasswordDialog = false;
        this.beforePassword = '';
        this.password = '';
        this.passwordConfirm = '';
      } catch (e) {
        this.changePasswordDialog = false;
        this.beforePassword = '';
        this.password = '';
        this.passwordConfirm = '';
        alert('비밀번호 변경 실패');
      }
    },
    async updateRemainTimeOnly() {
      try {
        const response = await axios.get('https://listening-able.visualcamp.net/api/auth/me', { withCredentials: true });
        if (response.status === 200 && response.data.center) {
          this.isLogin = true;
          this.remainTime = response.data.center.remainTime;
        } else {
          this.isLogin = false;
        }
      } catch (e) {
        this.isLogin = false;
      }
    },
    async me() {
      try {
        console.log('me');
        const response = await axios.get('https://listening-able.visualcamp.net/api/auth/me', { withCredentials: true });
        if (response.status === 200 && response.data.center) {
          console.log(response.data.center);
          this.isLogin = true;
          this.isAdmin = response.data.center.isAdmin;
          this.centerName = response.data.center.centerName;
          this.adminCenterName = response.data.center.centerName;
          // eslint-disable-next-line no-underscore-dangle
          this.centerId = response.data.center._id;
          // eslint-disable-next-line no-underscore-dangle
          this.adminCenterId = response.data.center._id;
          this.remainTime = response.data.center.remainTime;

          this.centerList = [];

          if (!this.isAdmin) {
            this.centerList.push({ centerCode: this.centerName, centerId: this.centerId });
          } else {
            const response2 = await axios.get('https://listening-able.visualcamp.net/api/center', { withCredentials: true });
            console.log('response2.data.centers', response2.data);
            response2.data.centers.forEach((item) => {
              this.centerList.push({
                centerName: item.centerName,
                // eslint-disable-next-line no-underscore-dangle
                centerId: item._id,
                centerCode: item.centerCode,
              });
            });
          }
        } else {
          this.isLogin = false;
        }
      } catch (e) {
        this.isLogin = false;
      }
    },
    async goToHome() {
      await axios.get('https://listening-able.visualcamp.net/api/auth/logout', { withCredentials: true });
      window.location = '/';
    },
    openChangePasswordDialog() {
      this.changePasswordDialog = true;
      this.beforePassword = '';
      this.password = '';
      this.passwordConfirm = '';
    },
    // eslint-disable-next-line no-unused-vars
    startTraining(item) {
      this.isTesting = true;
      this.trainingUserName = item.userName;
      this.trainingUserCode = item.userCode;
      // eslint-disable-next-line no-underscore-dangle
      this.trainingUserId = item._id;
    },
    goToNoticeBoard() {
      window.open('https://everlasting-route-d29.notion.site/c2f9c40248f3479b8d1a8292b5303a43?v=24978353915c4d048164552629bdad89');
    },
    popup() {
      this.popupDialog = true;
    },
    async login() {
      await this.me();
      this.isLogin = true;
      this.popupDialog = true;
    },
    async endTraining() {
      document.exitFullscreen();
      this.isTesting = false;
      this.currentLink = 0;
      await this.updateRemainTimeOnly();
    },
    goBack() {
      document.exitFullscreen();
      this.isTesting = false;
      this.currentLink = 0;
    },
    adminLogin() {
      this.isLogin = true;
      this.isAdmin = true;
      this.popupDialog = true;
    },
    history(item) {
      this.centerName = item.centerName;
      // eslint-disable-next-line no-underscore-dangle
      this.centerId = item._id;
      this.currentLink = 1;
    },
    reset() {
      this.centerName = this.adminCenterName;
      this.centerId = this.adminCenterId;
    },
    async resetAndgoTo1() {
      this.centerName = this.adminCenterName;
      this.centerId = this.adminCenterId;
      this.currentLink = 1;
      await this.$nextTick();
      this.$refs.usageTable.reload();
    },
  },
};
</script>
