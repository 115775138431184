<template>
  <v-row>
    <v-dialog
      v-model="deleteDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          주의가 필요한 작업
        </v-card-title>

        <v-card-text>
          삭제 후 정보를 되돌릴 수 없습니다. 진행하시겠습니까?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteDialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="red"
            text
            @click="deleteItem()"
          >
            삭제
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog
      v-if="startDialog"
      v-model="startDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          훈련 시작
        </v-card-title>

        <v-card-text>
          <b>{{editedItem.userName}}</b>의 신규 훈련을 시작합니다.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="startDialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="startTraining2()"
          >
            시작
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-col
      cols="9"
    >
      <v-sheet
        class="text-right"
        rounded="lg"
        min-height="50vh"
      >
        <v-row>
          <v-col cols="3">
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn class="ma-3" large outlined color="black"
                   :disabled="mode !== 0"
                   @click="enrollNewUser()">
              신규 회원 등록
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="20"
          sort-by="calories"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:item.training="{ item }">
            <v-btn
              small
              outlined
              color="primary"
              :disabled="mode !== 0 || item.trainingStatus !== '훈련' || item.centerId !== centerId ||
              item.isDeleted === 'true'"
              @click="startTraining(item)">
              시작
            </v-btn>
          </template>
          <template v-slot:item.history="{ item }">
            <v-btn
              small
              outlined
              color="black"
              :disabled="mode !== 0"
              @click="history(item)">
              이력
            </v-btn>
          </template>
          <template v-slot:item.modify="{ item, index }">
            <v-btn
              small
              outlined
              color="warning"
              :disabled="mode !== 0"
              @click="editItem(item, index)">
              수정
            </v-btn>
          </template>
          <template v-slot:item.delete="{ item, index }">
            <v-btn
              small
              outlined
              color="error"
              :disabled="mode !== 0"
              @click="loadDeleteDialog(index)">
              삭제
            </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
    <v-col
      cols="3"
    >
      <v-sheet
        v-if="mode === 1 && editedItem"
        class="text-center"
        rounded="lg"
        min-height="50vh"
      >
        <h2>회원 정보 수정</h2>
        <v-select
          class="pa-2"
          v-if="isAdmin"
          dense
          :items="centerList"
          label="소속 센터"
          hide-details
          outlined
          item-text="centerName"
          item-value="centerId"
          v-model="editedItem.centerId"
        ></v-select>
        <v-text-field
          class="pa-2"
          dense
          label="이름"
          outlined
          v-model="editedItem.userName"
          :rules="[() => !!editedItem.userName || '이름을 입려해주세요']"
        ></v-text-field>
        <v-radio-group
          class="pa-2"
          v-model="editedItem.gender"
          mandatory
          dense
          row
          label="성별"
        >
          <v-radio
            label="남자"
            value="남자"
          ></v-radio>
          <v-radio
            label="여자"
            value="여자"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          class="pa-2"
          outlined
          dense
          label="생년월일"
          v-mask="'####-##-##'"
          v-model="editedItem.birth"
          :rules="[() => (!!editedItem.birth && editedItem.birth.length === 10) || '생년 월일을 입력해주세요']"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="연락처"
          outlined
          hide-details
          v-mask="'###-####-####'"
          v-model="editedItem.phone"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="보호자 성함"
          outlined
          hide-details
          v-model="editedItem.parentName"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="보호자 연락처"
          hide-details
          outlined
          v-mask="'###-####-####'"
          v-model="editedItem.parentPhone"
        ></v-text-field>
        <v-textarea
          class="pa-2"
          outlined
          name="input-7-4"
          label="주소"
          dense
          hide-details
          v-model="editedItem.address"
        ></v-textarea>
        <v-textarea
          class="pa-2"
          outlined
          name="input-7-4"
          label="특이 사항"
          dense
          hide-details
          v-model="editedItem.memo"
        ></v-textarea>
        <v-radio-group
          class="pa-2"
          v-model="editedItem.trainingStatus"
          mandatory
          row
          dense
          label="상태"
        >
          <v-radio
            label="훈련"
            value="훈련"
          ></v-radio>
          <v-radio
            label="종료"
            value="종료"
          ></v-radio>
          <v-radio
            label="휴식"
            value="휴식"
          ></v-radio>
        </v-radio-group>
        <v-btn class="ma-3" large outlined color="primary"
               :disabled="!editedItem.userName || !editedItem.birth
                || editedItem.birth.length !== 10"
               @click="editItemConfirm">
          수정
        </v-btn>
        <v-btn class="ma-3" large outlined color="error" @click=resetMode>
          취소
        </v-btn>
      </v-sheet>
      <v-sheet
        v-if="mode === 2 && editedItem"
        class="text-center"
        rounded="lg"
        min-height="50vh"
      >
        <h2>신규 회원 등록</h2>
        <v-select
          class="pa-2"
          v-if="isAdmin"
          dense
          :items="centerList"
          label="소속 센터"
          hide-details
          outlined
          item-text="centerName"
          item-value="centerId"
          v-model="editedItem.centerId"
        ></v-select>
        <v-text-field
          class="pa-2"
          dense
          label="이름"
          outlined
          v-model="editedItem.userName"
          :rules="[() => !!editedItem.userName || '이름을 입려해주세요']"
        ></v-text-field>
        <v-radio-group
          class="pa-2"
          v-model="editedItem.gender"
          mandatory
          dense
          row
          label="성별"
        >
          <v-radio
            label="남자"
            value="남자"
          ></v-radio>
          <v-radio
            label="여자"
            value="여자"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          class="pa-2"
          outlined
          dense
          label="생년월일"
          v-mask="'####-##-##'"
          v-model="editedItem.birth"
          :rules="[() => (!!editedItem.birth && editedItem.birth.length === 10) || '생년 월일을 입력해주세요']"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="연락처"
          outlined
          hide-details
          v-mask="'###-####-####'"
          v-model="editedItem.phone"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="보호자 성함"
          outlined
          hide-details
          v-model="editedItem.parentName"
        ></v-text-field>
        <v-text-field
          class="pa-2"
          dense
          label="보호자 연락처"
          hide-details
          outlined
          v-mask="'###-####-####'"
          v-model="editedItem.parentPhone"
        ></v-text-field>
        <v-textarea
          class="pa-2"
          outlined
          name="input-7-4"
          label="주소"
          dense
          hide-details
          v-model="editedItem.address"
        ></v-textarea>
        <v-textarea
          class="pa-2"
          outlined
          name="input-7-4"
          label="특이 사항"
          dense
          hide-details
          v-model="editedItem.memo"
        ></v-textarea>
        <v-radio-group
          class="pa-2"
          v-model="editedItem.trainingStatus"
          mandatory
          row
          dense
          label="상태"
        >
          <v-radio
            label="훈련"
            value="훈련"
          ></v-radio>
          <v-radio
            label="종료"
            value="종료"
          ></v-radio>
          <v-radio
            label="휴식"
            value="휴식"
          ></v-radio>
        </v-radio-group>
        <v-btn class="ma-3" large outlined color="primary"
               :disabled="!editedItem.userName || !editedItem.birth
                || editedItem.birth.length !== 10"
               @click="enrollItemConfirm">
          등록
        </v-btn>
        <v-btn class="ma-3" large outlined color="error" @click="resetMode">
          취소
        </v-btn>
      </v-sheet>
      <v-sheet
        v-if="mode === 3 && historyItem"
        class="text-center"
        rounded="lg"
        min-height="50vh"
      >
        <h2>훈련 이력</h2>
        <v-data-table
          :headers="historyHeader"
          :items="historyItem"
          :items-per-page="20"
          class="elevation-1"
          sort-by="createdAtOrigin"
          sort-desc>
        </v-data-table>
        <v-btn class="ma-3" large outlined color="error" @click="resetMode">
          닫기
        </v-btn>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CenterTable2',
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    centerList: {
      type: [],
    },
    centerId: {
      type: String,
    },
  },
  async mounted() {
    console.log(this.centerList);
    const response = await axios.get('https://listening-able.visualcamp.net/api/user', { withCredentials: true });
    if (response.status === 200 && response.data.users) {
      this.items = response.data.users;
      this.items.forEach((item) => {
        if (item.birth) {
          if (item.birth.split('-').length === 3) {
            const year = parseInt(item.birth.split('-')[0], 10);
            const month = parseInt(item.birth.split('-')[1], 10);

            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth() + 1;

            const monthDiff = Math.max(currentMonth - month);

            let age = currentYear - year - 1;

            if (monthDiff >= 0) {
              age += 1;
            }

            const targetMonth = monthDiff >= 0 ? monthDiff : monthDiff + 12;

            console.log(item.birth, currentMonth, age, targetMonth);
            if (age < 7) {
              // eslint-disable-next-line no-param-reassign
              item.formatedAge = `만 ${age}세 ${targetMonth}개월`;
            } else {
              // eslint-disable-next-line no-param-reassign
              item.formatedAge = `만 ${age}세 `;
            }
          }
        }

        // eslint-disable-next-line no-param-reassign
      });
      console.log(this.items);
    }
  },
  methods: {
    startTraining(item) {
      console.log(item);
      this.editedItem = item;
      this.startDialog = true;
    },
    startTraining2() {
      this.startDialog = false;
      this.$emit('startTraining', this.editedItem);
      this.editedItem = null;
    },
    async enrollItemConfirm() {
      const response = await axios.post('https://listening-able.visualcamp.net/api/user', this.editedItem, { withCredentials: true });
      if (response.status === 200 && response.data.user) {
        this.mode = 0;
        this.editedItem = null;

        const item = response.data.user;

        item.createdAtOrigin = JSON.parse(JSON.stringify(item.createdAt));
        // eslint-disable-next-line prefer-destructuring
        item.createdAt = new Date(item.createdAt).toLocaleString().split(' GMT')[0];

        if (item.birth) {
          if (item.birth.split('-').length === 3) {
            const year = parseInt(item.birth.split('-')[0], 10);
            const month = parseInt(item.birth.split('-')[1], 10);

            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth() + 1;

            const monthDiff = Math.max(currentMonth - month);

            let age = currentYear - year - 1;

            if (monthDiff >= 0) {
              age += 1;
            }

            const targetMonth = monthDiff >= 0 ? monthDiff : monthDiff + 12;

            console.log(item.birth, currentMonth, age, targetMonth);
            if (age < 7) {
              // eslint-disable-next-line no-param-reassign
              item.formatedAge = `만 ${age}세 ${targetMonth}개월`;
            } else {
              // eslint-disable-next-line no-param-reassign
              item.formatedAge = `만 ${age}세 `;
            }
          }
        }

        this.items.push(item);
      }
    },
    async editItemConfirm() {
      // eslint-disable-next-line no-underscore-dangle
      const response = await axios.post(`https://listening-able.visualcamp.net/api/user/${this.editedItem._id}`, this.editedItem, { withCredentials: true });
      if (response.status === 200 && response.data.user) {
        this.mode = 0;
        this.editedItem = null;

        const item = JSON.parse(JSON.stringify(response.data.user));

        item.createdAtOrigin = JSON.parse(JSON.stringify(item.createdAt));
        // eslint-disable-next-line prefer-destructuring
        item.createdAt = new Date(item.createdAt).toLocaleString().split(' GMT')[0];

        if (item.birth) {
          if (item.birth.split('-').length === 3) {
            const year = parseInt(item.birth.split('-')[0], 10);
            const month = parseInt(item.birth.split('-')[1], 10);

            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth() + 1;

            const monthDiff = Math.max(currentMonth - month);

            let age = currentYear - year - 1;

            if (monthDiff >= 0) {
              age += 1;
            }

            const targetMonth = monthDiff >= 0 ? monthDiff : monthDiff + 12;

            console.log(item.birth, currentMonth, age, targetMonth);
            if (age < 7) {
              // eslint-disable-next-line no-param-reassign
              item.formatedAge = `만 ${age}세 ${targetMonth}개월`;
            } else {
              // eslint-disable-next-line no-param-reassign
              item.formatedAge = `만 ${age}세 `;
            }
          }
        }

        this.items[this.editedItemIndex] = item;
        this.items = JSON.parse(JSON.stringify(this.items));
        this.mode = 0;
        this.editedItem = null;
        this.editedItemIndex = -1;
      }
    },
    loadDeleteDialog(index) {
      this.deleteDialog = true;
      this.deletedItemIndex = index;
    },
    enrollNewUser() {
      this.mode = 2;
      this.editedItem = {
        centerId: this.centerId,
      };
    },
    async history(item) {
      // eslint-disable-next-line no-underscore-dangle
      const response = await axios.get(`https://listening-able.visualcamp.net/api/user/${item._id}/training`, { withCredentials: true });
      if (response.status === 200 && response.data.trainings) {
        this.mode = 3;
        this.historyItem = JSON.parse(JSON.stringify(response.data.trainings));

        // eslint-disable-next-line no-shadow
        this.historyItem.forEach((item) => {
          // eslint-disable-next-line no-param-reassign,prefer-destructuring
          item.createdAtOrigin = JSON.parse(JSON.stringify(item.createdAt));
          // eslint-disable-next-line no-param-reassign,prefer-destructuring
          item.createdAt = new Date(item.createdAt).toLocaleString().split(' GMT')[0];
          // eslint-disable-next-line no-param-reassign,prefer-destructuring
          item.trainingDuration = Math.max(1, Math.floor(item.trainingDuration / 1000 / 60));
        });
      }
    },
    editItem(item, index) {
      this.mode = 1;
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.editedItemIndex = index;
    },
    // eslint-disable-next-line no-unused-vars
    async deleteItem() {
      if (this.deletedItemIndex > -1) {
        // eslint-disable-next-line no-underscore-dangle
        const response = await axios.delete(`https://listening-able.visualcamp.net/api/user/${this.items[this.deletedItemIndex]._id}`, { withCredentials: true });
        console.log(response);
        if (response.status === 200) {
          this.items.splice(this.deletedItemIndex, 1);
          this.deleteDialog = false;
          this.deletedItemIndex = -1;
        }
      }
    },
    resetMode() {
      this.mode = 0;
      this.editedItem = null;
      this.editedItemIndex = -1;
      this.historyItem = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
  },
  computed: {
    headers() {
      if (this.isAdmin) {
        return [
          {
            text: '소속 센터명',
            align: 'center',
            value: 'centerName',
          },
          {
            text: '회원 번호',
            align: 'center',
            value: 'userCode',
          },
          {
            text: '이름',
            align: 'center',
            value: 'userName',
          },
          {
            text: '나이',
            align: 'center',
            value: 'formatedAge',
          },
          // {
          //   text: '훈련 총 시간',
          //   align: 'center',
          //   value: 'trainingTime',
          // },
          // {
          //   text: '훈련 총 횟수',
          //   align: 'center',
          //   value: 'trainingCount',
          // },
          // {
          //   text: '훈련 기간',
          //   align: 'center',
          //   value: 'trainingDuration',
          // },
          {
            text: '훈련 상태',
            align: 'center',
            value: 'trainingStatus',
          },
          {
            text: '삭제 상태',
            align: 'center',
            value: 'isDeleted',
          },
          {
            text: '훈련 시작', value: 'training', sortable: false, align: 'center',
          },
          {
            text: '훈련 이력', value: 'history', sortable: false, align: 'center',
          },
          {
            text: '회원 정보 변경', value: 'modify', sortable: false, align: 'center',
          },
          {
            text: '회원 삭제', value: 'delete', sortable: false, align: 'center',
          },
        ];
      }
      return [
        {
          text: '회원 번호',
          align: 'center',
          value: 'userCode',
        },
        {
          text: '이름',
          align: 'center',
          value: 'userName',
        },
        {
          text: '나이',
          align: 'center',
          value: 'formatedAge',
        },
        // {
        //   text: '훈련 총 시간',
        //   align: 'center',
        //   value: 'trainingTime',
        // },
        // {
        //   text: '훈련 총 횟수',
        //   align: 'center',
        //   value: 'trainingCount',
        // },
        // {
        //   text: '훈련 기간',
        //   align: 'center',
        //   value: 'trainingDuration',
        // },
        {
          text: '훈련 상태',
          align: 'center',
          value: 'trainingStatus',
        },
        {
          text: '훈련 시작', value: 'training', sortable: false, align: 'center',
        },
        {
          text: '훈련 이력', value: 'history', sortable: false, align: 'center',
        },
        {
          text: '유저 정보 변경', value: 'modify', sortable: false, align: 'center',
        },
        {
          text: '유저 삭제', value: 'delete', sortable: false, align: 'center',
        },
      ];
    },
  },
  data() {
    return {
      search: '',
      startDialog: false,
      historyHeader: [
        {
          text: '훈련일시',
          align: 'center',
          value: 'createdAt',
        },
        {
          text: '음악',
          align: 'center',
          value: 'music',
        },
        {
          text: '필터',
          align: 'center',
          value: 'filter',
        },
        {
          text: '볼륨',
          align: 'center',
          value: 'volume',
        },
        {
          text: '지연시간',
          align: 'center',
          value: 'latency',
        },
        {
          text: '훈련시간 (분)',
          align: 'center',
          value: 'trainingDuration',
        },
      ],
      historyItem: null,
      deleteDialog: false,
      deletedItemIndex: -1,
      editedItem: null,
      editedItemIndex: -1,
      mode: 0,
      menu2: false,
      items: [
        {
          centerName: '스카이 소아 청소년과 의원',
          uniqueCode: '000001',
          userCode: 'LA-001-0001',
          name: '박세근',
          age: 20,
          birth: '',
          trainingTime: '235 분',
          trainingCount: '5 회',
          trainingDuration: '3 개월',
          parentName: '아버지',
          parentPhone: '010-0000-0000',
          phone: '010-1111-1111',
          address: '서울시 서초구 매헌로16길 32',
          memo: '의사',
          trainingStatus: '훈련 중',
          deleteStatus: '정상',
          history: [
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
          ],
        },
        {
          centerName: '스카이 소아 청소년과 의원',
          uniqueCode: '000002',
          userCode: 'LA-001-0002',
          name: '김세종',
          age: 22,
          birth: '',
          trainingTime: '264 분',
          trainingCount: '6 회',
          trainingDuration: '4 개월',
          parentName: '아버지',
          parentPhone: '010-0000-0000',
          phone: '010-1111-1111',
          address: '서울시 서초구 매헌로16길 32',
          memo: '개발자',
          trainingStatus: '훈련 종료',
          deleteStatus: '정상',
          history: [
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
          ],
        },
        {
          centerName: '비주얼캠프',
          uniqueCode: '000003',
          userCode: 'LA-002-0001',
          name: '김김김',
          age: 25,
          birth: '',
          trainingTime: '124 분',
          trainingCount: '1 회',
          trainingDuration: '1 개월',
          parentName: '아버지',
          parentPhone: '010-0000-0000',
          phone: '010-1111-1111',
          address: '서울시 서초구 매헌로16길 32',
          memo: '개발자',
          trainingStatus: '휴식중',
          deleteStatus: '삭제',
          history: [
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
            {
              music: 'C1',
              filter: 'H2',
              volume: 'V1',
              latency: 'P1',
              date: '2022/08/03',
              trainingTime: '30',
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
